import axios from "axios";
import userTableMock from "../__mocks__/userTableMock";
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const USER_URL = `${process.env.REACT_APP_API_URL}/api/v1/users/current`;

export function login(username, password) {
  let requestBody = "client_id=core_client&grant_type=password&client_secret=secret&username=" + username + "&password=" + password;
  let config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic Y29yZV9jbGllbnQ6c2VjcmV0",
    },
  }

  return axios.post(LOGIN_URL + "/oauth/token", requestBody, config)
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export const getUser = () => {
  return axios.get(USER_URL)
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // return axios.get(ME_URL);
  const user = userTableMock.find((x) => x.authToken === "auth-token-6829bba69dd3421d8762-991e9e806dbf");
  try {
    let { data } = await getUser();
    const mergeUser = {
      ...user,
      ...data?.data
    }
    return mergeUser;
  } catch (error) {
  }
  return user;
}
