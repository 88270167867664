export const CODE_SUCCESS = 200;

export const MENU_CHUA_DANG_NHAP = [
    {
        path: "/khai-bao",
        title: "Khai báo",
        children: [
            {
                path: "/khai-bao/khai-bao-thong-tin-sot-xuat-huyet",
                title: "Khai báo sốt xuất huyết"
            }
        ]
    }
];

export const MENU_NGUOI_DAN = [
  {
      path: "/khai-bao",
      title: "Khai báo",
      children: [
          {
              path: "/khai-bao/khai-bao-thong-tin-sot-xuat-huyet",
              title: "Khai báo sốt xuất huyết"
          }
      ]
  }
]

export const MENU_CO_QUAN_Y_TE = [
    {
        path: "/dashboard",
        title: "Trang chủ"
    },
    {
        path: "/khai-bao",
        title: "Khai báo",
        children: [
            {
                path: "/khai-bao/khai-bao-thong-tin-sot-xuat-huyet",
                title: "Khai báo sốt xuất huyết"
            }
        ]
    },
    {
        path: "/ho-so-chung-tu",
        title: "Hồ sơ chứng từ",
        children: [
            {
                path: "/ho-so-chung-tu/ho-so-mac-benh-sot-xuat-huyet",
                title: "Hồ sơ mắc bệnh sốt xuất huyết",
                children: [
                    {
                        path: "/ho-so-chung-tu/ho-so-mac-benh-sot-xuat-huyet/danh-sach-mac-benh",
                        title: "Danh sách mắc bệnh",
                    }
                ]
            },
        ]
    }
]

export const STATUS_ACTION = {
    ADD: "add",
    DELETE: "delete",
    VIEW: "view",
    EDIT: "edit",
    CONFIRM: "confirm",
    REFUSE: "refuse",
}

export const REGEX = {
    TEN: /^[^~`!@#$%^&*()+=\-[\]\\';,/{}|\\":<>?\d]+$/,
    AZ_09: /^[a-zA-Z0-9]*$/,
    CHARACTER20: /^.{6,20}$/,
    CHARACTER9or12: /^\d{9}(\d{3})?$/,
    CHARACTER10: /^[a-zA-Z0-9]{10}$/,
    CHARACTER50: /^.{1,50}$/,
    CHARACTER255: /^.{1,255}$/,
    CHECK_PHONE: /^(0|\+84)\d{9,10}$/,
    YEAR: /^.{4,5}$/,
}

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_TOTAL_PAGES = 0;
export const DEFAULT_TOTAL_ELEMENTS = 0;

export const KEY_NAME = {
    ENTER: "Enter",
}

export const ACCOUNT_LEVEL_CODE = {
    PROVINCE: 1,
    DISTRICT: 2,
    SUB_DISTRICT: 3,
}