import React from 'react'
import { ButtonToolbar, Form, OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap'
import clsx from "clsx";

const TablePagination = (props) => {
  const {
    handlePagesChange,
    handleRowsPerPageChange,
    rowsPerPage,
    rowsForPage,
    page,
    setPage,
    setRowsPerPage,
    totalPages,
    totalElements,
    className,
    hasFirst,
    hasLast
  } = props
  let startIndex = (page - 1) * rowsPerPage + 1;
  let endIndex = Math.min(startIndex + rowsPerPage - 1, totalElements);
  return (
    <div className={clsx("d-flex justify-content-between border-top border-bottom align-items-center bg-white ", className)}>
      <div className='d-flex justify-content-center align-items-center px-3'>
        <span className='min-w-60px'>Số hàng</span>
        <Form.Control
          as='select'
          size='sm'
          value={rowsPerPage}
          onChange={(e) => {
            setPage(1)
            handleRowsPerPageChange(e, setRowsPerPage)
          }}
          className='footer-select'
        >
          {rowsForPage.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
        <span className='ml-3 min-w-100px'>{startIndex} - {endIndex} trong {totalElements}</span>
      </div>
      <Pagination className='pagination justify-content-center align-items-center py-3 px-0'>
        <ButtonToolbar>
          <Pagination.Prev
            disabled={page === 1}
            onClick={() => handlePagesChange(-1, page, setPage, totalPages)}
          />
          <div className='d-flex align-items-center ms-2 me-2 min-w-30px px-3'>
            {page} / {totalPages}
          </div>
          <Pagination.Next
            disabled={page === totalPages}
            onClick={() => handlePagesChange(1, page, setPage, totalPages)}
          />
        </ButtonToolbar>
      </Pagination>
    </div>
  )
}
export { TablePagination }
