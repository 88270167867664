/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { Link } from "react-router-dom";

export function QuickUserToggler() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <div className="d-flex align-items-center">
      <div className="mr-2">
        {isAuthorized
          ?
          <></>
          :
          <Link to="/logout" className="btn btn-light-primary font-weight-bold">Đăng nhập</Link>
        }
      </div>

      {(!layoutProps.offcanvas && isAuthorized) && <UserProfileDropdown />}
      
      <div>
        <span>VN</span> | <span>EN</span>
      </div>
    </div>
  );
}
