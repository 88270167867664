import React from "react";
import "./StatusColor.scss";

const StatusColor = ({ children, type }) => {
  return (
    <div className={`status-${type}`}>
      {children}
    </div>
  );
};

export default StatusColor;