import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import Select, { GetOptionLabel } from "react-select";

export const KEY = {
    ENTER: "Enter",
    SPACE: "Space",
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: state.isDisabled ? '#eff2f5' : '#fff',
        borderColor: '#E4E6EF',
        borderRadius: "0.475rem",
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#369DC3',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        margin: '0px',
        height: '32px'
    }),
    indicatorSeparator: (state) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
    }),
    option: (provided, state) => {
        return ({
            ...provided,
            color: state.isFocused ? "white" : "#5E6278",
            backgroundColor: state.isFocused ? '#2d2d43' : "white",
            '&:hover': {
                background: '#2d2d43',
                color: "#fff",
            },
            zIndex: state.isSelected ? 9999 : 'auto',
        })
    },
};

export const Autocomplete = (props) => {
    const { options, onChange, searchFunction, searchObject, valueField, responseUrl } = props;
    const [optionList, setOptionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isFocus, setIsFocus] = useState(false);

    const handleKeyDown = (event) => {
        if (KEY.SPACE === event.code && !(event.target).value) {
            event.preventDefault();
        }
    };

    const getDataByResponseUrl = (res) => {
        let result = res;
        responseUrl.split('.').forEach(item => {
            result = result[item];
        });
        return result;
    }

    useEffect(() => {
        let active = true;
        const fetchData = async () => {
            if (options?.length > 0 && !props.searchFunction) {
                setOptionList(options);
            } else if (!isLoading) {
                setIsLoading(true);
                try {
                    if (props.searchObject !== undefined) {

                        const res = await props.searchFunction?.(props.searchObject);
                        if (active && res) {
                            responseUrl
                                ? setOptionList(getDataByResponseUrl(res))
                                : setOptionList(res?.data?.data)
                            setIsLoading(false);
                        }
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
        };

        (isFocus && optionList?.length === 0) && fetchData();

        return () => {
            active = false;
            setIsLoading(false)
        };
    }, [options, searchFunction, searchObject, isFocus, props.value]);

    useEffect(() => {
        setOptionList([])
        setSelectedValue(null)
    }, props?.dependencies || []);

    useEffect(() => {
        getValue();
    }, [props.value]);

    const handleChange = (selectedOption) => {
        setSelectedValue(selectedOption);
        onChange(selectedOption);
    };

    const getValue = () => {
        if (props?.value) {
            setSelectedValue(props?.value)
        }
        else {
            setSelectedValue(null);
        }

    };

    return (
        <>
            <Select
                isClearable={props?.isClearable !== undefined ? props?.isClearable : true}
                getOptionLabel={(option) =>
                    props.optionLabel
                        ? option[props.optionLabel]
                        : option.name
                }
                getOptionValue={(option) => option}
                onKeyDown={(event) => handleKeyDown(event)}
                backspaceRemovesValue={props?.backspaceRemovesValue}
                isSearchable={props?.isSearchable !== undefined ? props?.isSearchable : true}
                options={optionList}
                noOptionsMessage={({ inputValue }) => `Không tìm thấy lựa chọn cho "${inputValue}"`}
                // className={props?.className ? props?.className : "w-100"}
                name={props?.name}
                value={selectedValue}
                id={props?.id}
                key={props?.key}
                closeMenuOnSelect={props?.closeMenuOnSelect}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                isDisabled={props?.isDisabled}
                isLoading={isLoading}
                styles={customStyles}
                minMenuHeight={props?.minMenuHeight || 250}
                maxMenuHeight={props?.maxMenuHeight}
                placeholder={<p className="custom-placeholder spaces m-0">{props?.placeholder || "Chọn..."}</p>}
                onChange={handleChange}
                isMulti={props?.isMulti}
                menuPlacement="auto"
                className={clsx(props?.className, (props?.errors && props?.touched) && "ac-is-invalid")}
            />
            {props?.touched && props?.errors && (
                <div className={(props?.touched && props?.errors) ? "d-block" : "d-none"}
                    style={{
                        fontSize: "0.9rem",
                        fontWeight: "400",
                        color: "#F64E60",
                        marginTop: "3.25px"
                    }}
                >{props?.errors}</div>
            )}
        </>
    );
};
