/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { shallowEqual, useSelector } from "react-redux";
import { MENU_CHUA_DANG_NHAP, MENU_CO_QUAN_Y_TE, MENU_NGUOI_DAN } from "../../../../../app/appConstants";

export function HeaderMenu({ layoutProps }) {
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
          isAuthorized: auth.user != null,
        }),
        shallowEqual
      );
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        if (!isAuthorized) {
            setMenu(MENU_CHUA_DANG_NHAP);
        } else {
            if(user) {
                setMenu(MENU_CO_QUAN_Y_TE)
            } else{
                setMenu(MENU_NGUOI_DAN);
            }
        }
    }, [isAuthorized]);

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {menu.map(item => {
                return <MenuItem layoutProps={layoutProps} item={item} />
            })}
        </ul>
    </div>;
}
