import React, { useContext } from 'react';
import { KhaiBaoThongTinSXH } from '../../khai-bao-thong-tin-sxh/KhaiBaoThongTinSXH';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Autocomplete } from '../../../modules/Component/Autocomplete';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { LIST_LOAI_XAC_NHAN } from '../constants/DanhSachMacBenhConstants';
import { VALIDATE_MESSAGE } from '../../khai-bao-thong-tin-sxh/constants';
import { confirmPatient } from '../services/DanhSachMacBenhServices';
import { CODE_SUCCESS } from '../../../appConstants';
import { toast } from 'react-toastify';
import { useState } from 'react';
import TableCustom from '../../../modules/Component/table-custom/TableCustom';
import { formatDateTable } from '../../../utils/utils';
import { format } from 'date-fns';
import { shallowEqual, useSelector } from 'react-redux';
import { AppContext } from '../../../modules/Component/appContext/AppContextProvider';

function XacNhanDialog({ open, handleCloseDialog, rowData, isConfirmAction, isAddAction, isView }) {
  const { setIsLoading } = useContext(AppContext);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [isAdminAdd, setIsAdminAdd] = useState(false);
  const [nguoiXacNhan, setNguoiXacNhan] = useState(null);

  const formik = useFormik({
    initialValues: {
      xnDateTime: new Date(),
      xnStatus: null,
      xnFullName: user?.username || "",
      xnPhone: "",
      xnCoSo: user?.organization?.name || "",
      xnMoTa: "",
    },
    validationSchema: Yup.object({
      xnStatus: Yup.object().nullable().required(VALIDATE_MESSAGE.REQUIRED),
      xnFullName: Yup.string().nullable().required(VALIDATE_MESSAGE.REQUIRED),
      xnPhone: Yup.string().nullable().required(VALIDATE_MESSAGE.REQUIRED),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      handleXacMinhCaBenh({
        ...values,
        id: rowData?.id,
        xnStatus: values?.xnStatus?.status,
        xnDateTime: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')
      });
    }
  })

  const handleXacMinhCaBenh = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await confirmPatient(values);
      if (data?.code === CODE_SUCCESS) {
        setIsLoading(false);
        formik.resetForm();
        handleCloseDialog();
        toast.success("Xác minh ca bệnh thành công");
      } else {
        setIsLoading(false);
        toast.error("Có lỗi xảy ra vui lòng thử lại");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Có lỗi xảy ra vui lòng thử lại");
    }
  }

  const handleGetNguoiXacNhan = (data) => {
    setNguoiXacNhan(data);
  }

  const columns = [
    {
      name: 'STT',
      field: '',
      headerStyle: {
        minWidth: "20px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData, index) => (index + 1),
    },
    {
      name: 'Thời điểm xác nhận',
      field: 'xnDateTime',
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData, index) => formatDateTable(rowData?.xnDateTime),
    },
    {
      name: 'Mô tả',
      field: 'xnMoTa',
      headerStyle: {
        minWidth: "200px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      name: 'Cơ sở xác nhận',
      field: 'xnCoSo',
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      name: 'Người xác nhận',
      field: 'xnFullName',
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      name: 'Số điện thoại',
      field: 'xnPhone',
      headerStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
    },
  ]

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="lg">
      <DialogTitle className="py-4 header-dialog">
        {rowData?.id ? isConfirmAction ? "Xác minh" : isView ? "Xem" : "Cập nhật" : "Thêm mới"} ca bệnh
      </DialogTitle>

      <Form onSubmit={formik.handleSubmit}>
        <DialogContent className='scroll-modal'>
          <KhaiBaoThongTinSXH handleCloseDialog={handleCloseDialog} handleGetNguoiXacNhan={handleGetNguoiXacNhan} isView={isView} isAdminAdd={isAdminAdd} rowData={rowData} isConfirmAction={isConfirmAction} isAddAction={isAddAction} />
          {isConfirmAction ? <div>
            <Row className="mt-4">
              <Col>
                <h4 className="text-primary mb-2">Thông tin xác nhận</h4>
              </Col>
            </Row>

            <Row>
              <Col xl="3">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Loại xác nhận
                    <span className="text-danger pl-1">*</span>
                  </Form.Label>
                  <Autocomplete
                    values={formik.values?.xnStatus || null}
                    options={LIST_LOAI_XAC_NHAN}
                    onChange={(selectedOption) => formik.setFieldValue("xnStatus", selectedOption)}
                    errors={formik.errors?.xnStatus}
                    touched={formik.touched?.xnStatus}
                  />
                </Form.Group>
              </Col>

              <Col xl="3">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Họ và tên người xác nhận
                    <span className="text-danger pl-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="xnFullName"
                    value={formik.values?.xnFullName || ""}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched?.xnFullName && formik.errors?.xnFullName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.xnFullName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl="3">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Điện thoại người xác nhận
                    <span className="text-danger pl-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="xnPhone"
                    value={formik.values?.xnPhone || ""}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched?.xnPhone && formik.errors?.xnPhone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.xnPhone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl="3">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Cơ sở xác nhận
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="xnCoSo"
                    value={formik.values?.xnCoSo || ""}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">
                    Mô tả
                  </Form.Label>
                  <Form.Control
                    className="resize-none"
                    type="text"
                    as="textarea"
                    rows={2}
                    name="xnMoTa"
                    value={formik.values?.xnMoTa || ""}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div> : <></>}

          {isView &&
            <Row>
              <Col xl="12" className="mb-2 mt-2">
                <h4 className="text-primary">Lịch sử xác nhận</h4>
              </Col>

             <Col>
               <TableCustom columns={columns} data={nguoiXacNhan?.xnFullName ? [nguoiXacNhan] : []} />
             </Col>
            </Row>
          }
        </DialogContent>

        <DialogActions className='d-lex justify-content-center py-3'>
          <Button variant="secondary" onClick={handleCloseDialog}>Hủy</Button>
          {isConfirmAction && <Button variant="primary" type="submit">Xác minh ca bệnh</Button>}
          {isAddAction && <Button variant="primary" onClick={() => setIsAdminAdd(!isAdminAdd)}>{rowData?.id ? "Cập nhật" : "Thêm mới"}</Button>}
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default XacNhanDialog