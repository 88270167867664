import React, { createContext, useState } from 'react';
import PageLoading from '../page-loading/PageLoading';

export const AppContext = createContext({
  isLoading: false,
  setIsLoading: () => { }
})

function AppContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading
      }}
    >
      {isLoading && <PageLoading />}
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider