import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import "./SearchInput.scss";
import SearchIcon from '@material-ui/icons/Search';

const SearchInput = ({ ...props }) => {
    let { value, name, handleChange, handleKeyDown, handleSearch, type, className, placeholder, handleKeyUp } = props;
    return (
        <Form.Group className='position-relative mb-0 min-w-350px'>
            <FormControl
                className={`form-control customs-input ${className ? className : ""}`}
                value={value}
                name={name}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                placeholder={placeholder ? placeholder : ""}
                type={type ? type : "text"}
            />
            <div className="searchTextField" onClick={handleSearch}>
                <SearchIcon />
            </div>
        </Form.Group>
    );
};

export default SearchInput