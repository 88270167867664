import React, { useEffect, useState } from 'react';
import "./CustomCaptcha.scss";

const CustomCaptcha = ({ length, getValue }) => {

    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const [captchaText, setCaptchaText] = useState(generateRandomString(length));

    useEffect(() => {
        getValue(captchaText);
    }, [captchaText])

    const getRandomFontWeight = () => {
        const weights = ['normal', 'bold', 'bolder', 'lighter'];
        return weights[Math.floor(Math.random() * weights.length)];
    };

    const getRandomFontStyle = () => {
        const styles = ['normal', 'italic', 'oblique'];
        return styles[Math.floor(Math.random() * styles.length)];
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const refreshCaptcha = (e) => {
        e.preventDefault();
        setCaptchaText(generateRandomString(length));
    };

    const getRandomBlur = () => {
        return Math.random() * 3 + 1;
    };

    return (
        <div className='custom-captcha'>
            <div className='cover'></div>
            <div style={{ fontSize: '24px', fontFamily: 'Arial, sans-serif', userSelect: "none" }}>
                {captchaText.split('').map((char, index) => (
                    <span
                        key={index}
                        style={{
                            transform: `rotate(${Math.random() * 20 - 10}deg)`,
                            fontWeight: getRandomFontWeight(),
                            fontStyle: getRandomFontStyle(),
                            color: getRandomColor(),
                            textShadow: `0 0 ${getRandomBlur()}px #000`,
                            paddingRight: "4px"
                        }}
                    >
                        {char}
                    </span>
                ))}
            </div>
            <i className="fas fa-sync-alt cursor-pointer" onClick={refreshCaptcha}></i>
        </div>
    );
};

export default CustomCaptcha;
