import React, { useState } from 'react'
import ThongTinThongKe from './component/ThongTinThongKe'
import DanhSachMacBenhTable from './component/DanhSachMacBenhTable'
import { KhaiBaoThongTinSXH } from '../khai-bao-thong-tin-sxh/KhaiBaoThongTinSXH'
import { useFormik } from 'formik'

function DanhSachMacBenh() {
  const [searchValues, setSearchValues] = useState({});
  const formik = useFormik({
    initialValues: {
      province: null,
      district: null,
      subDistrict: null,
      fullName: "",
      gender: "",
      soBHYT: "",
      soCCCD: "",
      phone: "",
      ageFrom: "",
      toAge: "",
      ngaySinh: "",
      namSinh: "",
      ngaySinhFrom: "",
      toNgaySinh: "",
      kyKhaiBao: null,
      namKhaiBao: null,
      thangQuy: null,
      kyThongKeFrom: "",
      toKyThongKe: "",
      thongKeTheo: null,
      doiTuong: null,
      phanLoaiCaBenh: null,
      noiDieuTriHienTai: null,
      coSoDieuTri: null,
      bieuHienBenh: null
    },
    onSubmit: (values) => {
      setSearchValues(values);
    }
  })
  return (
    <>
      <div>
        <ThongTinThongKe formik={formik} />

        <DanhSachMacBenhTable searchValues={searchValues} />
      </div>
    </>
  )
}

export default DanhSachMacBenh