import axios from "axios";
const API_PATH = process.env.REACT_APP_API_URL;

export const getAllPatients = (params) => {
  const config = {
    params: params,
  }
  return axios.get(API_PATH + "/patients/ds-patient", config)
}

export const confirmPatient = (data) => {
  return axios.put(API_PATH + `/patients/confirm/${data?.id}`, data);
}

export const getPatientById = (id) => {
  return axios.get(API_PATH + `/patients/${id}`)
}
