import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { KhaiBaoThongTinSXH } from "./pages/khai-bao-thong-tin-sxh/KhaiBaoThongTinSXH";
import HoSoSucKhoe from "./pages/ho-so-suc-khoe/HoSoSucKhoe";
import TraCuuDinhDanh from "./pages/tra-cuu/tra-cuu-dinh-danh/TraCuuDinhDanh";
import TraCuuHoSoSucKhoe from "./pages/tra-cuu/tra-cuu-ho-so-suc-khoe/TraCuuHoSoSucKhoe";
import TimKiemPhongKham from "./pages/tra-cuu/tim-kiem-phong-kham/TimKiemPhongKham";
import TimKiemBacSi from "./pages/tra-cuu/tim-kiem-bac-si/TimKiemBacSi";
import CamNang from "./pages/cam-nang/CamNang";
import TaiLieu from "./pages/tai-lieu/TaiLieu";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { AuthPage, Logout } from "./modules/Auth";
import DanhSachMacBenh from "./pages/danh-sach-mac-benh/DanhSachMacBenh";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage({ path = "/khai-bao/khai-bao-thong-tin-sot-xuat-huyet" }) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={path} />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/khai-bao">
          <Route path="/khai-bao/khai-bao-thong-tin-sot-xuat-huyet" component={KhaiBaoThongTinSXH} />
        </Route>
        <Route path="/ho-so-suc-khoe" component={HoSoSucKhoe} />
        <Route path="/cam-nang" component={CamNang} />
        <Route path="/tra-cuu/tra-cuu-dinh-danh" component={TraCuuDinhDanh} />
        <Route path="/tra-cuu/tra-cuu-ho-so-suc-khoe" component={TraCuuHoSoSucKhoe} />
        <Route path="/tra-cuu/tim-kiem-phong-kham" component={TimKiemPhongKham} />
        <Route path="/tra-cuu/tim-kiem-bac-si" component={TimKiemBacSi} />
        <Route path="/tai-lieu" component={TaiLieu} />
        <Route path="/ho-so-chung-tu/ho-so-mac-benh-sot-xuat-huyet/danh-sach-mac-benh" component={DanhSachMacBenh} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
