export const LIST_LOAI_XAC_NHAN = [
  {
    status: 4,
    name: "Xác nhận đúng ca bệnh sốt xuất huyết"
  },
  {
    status: 3,
    name: "Không phải sốt xuất huyết"
  },
]

export const STATUS_PLCB = {
  CHUA_XAC_NHAN: 1,
  DANG_DIEU_TRA_XAC_MINH: 2,
  XAC_NHAN_LOAI_TRU: 3,
  XAC_NHAN_DUNG_CA_BENH: 4,
}

export const LIST_STATUS_PLCB = [
  {
    status: STATUS_PLCB.CHUA_XAC_NHAN,
    name: "Chưa xác nhận"
  },
  {
    status: STATUS_PLCB.DANG_DIEU_TRA_XAC_MINH,
    name: "Đang điều tra xác minh"
  },
  {
    status: STATUS_PLCB.XAC_NHAN_LOAI_TRU,
    name: "Xác nhận loại trừ"
  },
  {
    status: STATUS_PLCB.XAC_NHAN_DUNG_CA_BENH,
    name: "Xác nhận đúng ca bệnh"
  },
]

export const GIOI_TINH = [
  {
    code: 0,
    name: "Tất cả"
  },
  {
    code: 1,
    name: "Nam"
  },
  {
    code: 2,
    name: "Nữ"
  },
]

export const KY_KHAI_CAO = [
  {
    code: "THANG",
    name: "Tháng/Quý"
  },
  {
    code: "NAM",
    name: "Năm"
  },
  {
    code: "KXD",
    name: "Không xác định"
  },
]

export const OPTION_NAM = [
  {
    name: "2023",
  },
  {
    name: "2022",
  },
  {
    name: "2021",
  },
  {
    name: "2020",
  },
  {
    name: "2019",
  },
  {
    name: "2018",
  },
  {
    name: "2017",
  },
  {
    name: "2016",
  },
]

export const THANG_QUY = [
  {
    code: "TQTK",
    name: "Tháng/Quý thống kê"
  }
]

export const THONG_KE_THEO_NGAY = [
  {
    code: "NKP",
    name: "Ngày khởi phát"
  },
  {
    code: "NKB",
    name: "Ngày khai báo"
  },
]

export const DOI_TUONG = [
  {
    code: "ALL",
    name: "Tất cả"
  },
  {
    code: "NBKB",
    name: "Người bệnh khai báo"
  },
  {
    code: "BSN",
    name: "Bác sĩ nhập"
  },
  {
    code: "LT",
    name: "Liên thông từ CSKCB"
  },
]

export const PHAN_LOAI_CA_BENH = [
  {
    code: "ALL",
    name: "Tất cả"
  },
  {
    code: "DXM",
    name: "Đã xác minh ca bệnh"
  },
  {
    code: "XMD48h",
    name: "Xác minh đúng 48h"
  },
  {
    code: "XMM48h",
    name: "Xác minh muộn sau 48h"
  },
  {
    code: "XNDCB",
    name: "Xác nhận đúng ca bệnh sốt xuất huyết"
  },
  {
    code: "XNLT",
    name: "Xác nhận loại trừ/Không phải sốt xuất huyết"
  },
  {
    code: "CXM",
    name: "Chưa xác minh/Đang điều tra xác minh"
  },
]

export const NOI_DIEU_TRI_HIEN_TAI = [
  {
    code: "ALL",
    name: "Tất cả"
  },
  {
    code: "DDTTN",
    name: "Đang điều trị tại nhà"
  },
  {
    code: "DDTBV",
    name: "Đang điều trị tại bệnh viện"
  },
  {
    code: "DKB",
    name: "Đã khỏi bệnh"
  },
]