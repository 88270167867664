import React from "react";

export const TableRow = (props) => {
  const { row, columns, index, itemList, isSingleSelect, handleCheckRadio } = props;
  return (
    <>
      <tr className="border-bottom"
        onClick={() => isSingleSelect ? handleCheckRadio(true, index, itemList) : {}}
      >
        {columns?.map((column) => (
          column?.render ?
            <td
              className="td-vertical-center p-table"
              style={column?.cellStyle}
            >
              {column.render ? column.render(row, index) : row[column?.field]}
            </td> : <td
              className="td-vertical-center p-table"
              key={index}
              style={column?.cellStyle}
            >
              {row[column?.field]}
            </td>
        ))}
      </tr>
    </>
  );
};