import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Autocomplete } from '../../../modules/Component/Autocomplete'
import { getCoSoYTe, getDistricts, getProvinces, getSubDistricts } from '../../khai-bao-thong-tin-sxh/KhaiBaoThongTinSXHService';
import { BIEU_HIEN_BENH } from '../../khai-bao-thong-tin-sxh/constants';
import { DOI_TUONG, GIOI_TINH, KY_KHAI_CAO, NOI_DIEU_TRI_HIEN_TAI, OPTION_NAM, PHAN_LOAI_CA_BENH, THANG_QUY, THONG_KE_THEO_NGAY } from '../constants/DanhSachMacBenhConstants';
import { shallowEqual, useSelector } from 'react-redux';

function ThongTinThongKe({ formik }) {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [timKiemNangCao, setTimKiemNangCao] = useState(false);
  const searchObjectProvince = {
    pageIndex: 1,
    pageSize: 1000,
    code: user?.organization?.provinceCode
  }
  const searchObjectDistrict = {
    pageIndex: 1,
    pageSize: 1000,
    code: user?.organization?.districtCode
  }
  const searchObjectSubDistrict = {
    pageIndex: 1,
    pageSize: 1000,
    code: user?.organization?.subDistrictCode
  }

  const handleTimKiemNangCao = () => {
    setTimKiemNangCao(!timKiemNangCao);
  }

  const handleChangeSeclection = (name, value) => {
    switch (name) {
      case "province":
        formik.setFieldValue(name, value);
        formik.setFieldValue("district", null);
        formik.setFieldValue("subDistrict", null);
        break;
      case "district":
        formik.setFieldValue(name, value);
        formik.setFieldValue("subDistrict", null);
        break;

      default:
        formik.setFieldValue(name, value);
        break;
    }
  }
  
  return (
    <div className="container-wrapper">
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h4>Thông tin thống kê</h4>

        <div className="d-flex">
          {/* <Button className='min-w-100px' onClick={handleTimKiemNangCao}>
            Tìm kiếm nâng cao
          </Button> */}
        </div>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Tỉnh/Thành phố
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                className="z-index-6"
                searchFunction={getProvinces}
                searchObject={searchObjectProvince}
                responseUrl="data.content"
                value={formik.values?.province || null}
                onChange={selectedOption => handleChangeSeclection("province", selectedOption)}
              />
            </Form.Group>
          </Col>
          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Quận/Huyện
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                className="z-index-5"
                searchFunction={getDistricts}
                searchObject={{ ...searchObjectDistrict, provinceCode: formik.values?.province?.code || user?.organization?.provinceCode}}
                responseUrl="data.content"
                value={formik.values?.district || null}
                onChange={selectedOption => handleChangeSeclection("district", selectedOption)}
                dependencies={[formik.values?.province]}
              />
            </Form.Group>
          </Col>
          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Xã/Phường
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                className="z-index-4"
                searchFunction={getSubDistricts}
                searchObject={{ ...searchObjectSubDistrict, districtCode: formik.values?.district?.code || user?.organization?.districtCode}}
                responseUrl="data.content"
                value={formik.values?.subDistrict || null}
                onChange={selectedOption => handleChangeSeclection("subDistrict", selectedOption)}
                dependencies={[formik.values?.province, formik.values?.district]}
              />
            </Form.Group>
          </Col>
          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Họ và tên</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formik.values?.fullName || ""}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Col>

          {/* <Col xl>
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Đối tượng</Form.Label>
              <Autocomplete
                options={DOI_TUONG}
                value={formik.values?.doiTuong || null}
                onChange={selectedOption => handleChangeSeclection("doiTuong", selectedOption)}
                className="z-index-3"
              />
            </Form.Group>
          </Col> */}
        </Row>

        {/* {timKiemNangCao &&
          <Row>
            <Col xl="2">
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Số thẻ BHYT</Form.Label>
                <Form.Control
                  name="soBHYT"
                  value={formik.values?.soBHYT || ""}
                  onChange={formik.handleChange} type="text"
                />
              </Form.Group>
            </Col>

            <Col xl="3">
              <Row>
                <Col xl="6"> <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Số CMND/CCCD</Form.Label>
                  <Form.Control
                    name="soCCCD"
                    value={formik.values?.soCCCD || ""}
                    onChange={formik.handleChange}
                    type="text"
                  />
                </Form.Group>
                </Col>
                <Col xl="6">
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Số điện thoại</Form.Label>
                    <Form.Control
                      name="phone"
                      value={formik.values?.phone || ""}
                      onChange={formik.handleChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col xl="4">
              <Row>
                <Col xl="5">
                  <Row>
                    <Col xl="6">
                      <Form.Group className="mb-3" style={{ minWidth: "45px" }}>
                        <Form.Label className="mb-0">Tuổi từ</Form.Label>
                        <Form.Control
                          name="ageFrom"
                          value={formik.values?.ageFrom || ""}
                          onChange={formik.handleChange}
                          className='no-spinners'
                          type="number"
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="6">
                      <Form.Group className="mb-3" style={{ minWidth: "55px" }}>
                        <Form.Label className="mb-0">Đến tuổi</Form.Label>
                        <Form.Control
                          name="toAge"
                          value={formik.values?.toAge || ""}
                          onChange={formik.handleChange}
                          className='no-spinners'
                          type="number" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <Col xl="7">
                  <Row>
                    <Col xl="8">
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-0">Ngày sinh</Form.Label>
                        <Form.Control
                          name="ngaySinh"
                          value={formik.values?.ngaySinh || ""}
                          onChange={formik.handleChange}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="4">
                      <Form.Group className="mb-3" style={{ minWidth: "65px" }}>
                        <Form.Label className="mb-0">Năm sinh</Form.Label>
                        <Form.Control
                          name="namSinh"
                          value={formik.values?.namSinh || ""}
                          onChange={formik.handleChange}
                          className='no-spinners'
                          type="number"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xl="3">
              <Row>
                <Col xl="6">
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">
                      Ngày sinh từ
                    </Form.Label>
                    <Form.Control
                      name="ngaySinhFrom"
                      value={formik.values?.ngaySinhFrom || ""}
                      onChange={formik.handleChange}
                      type="date"
                    />
                  </Form.Group>
                </Col>

                <Col xl="6">
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Đến</Form.Label>
                    <Form.Control
                      name="toNgaySinh"
                      value={formik.values?.toNgaySinh || ""}
                      onChange={formik.handleChange}
                      type="date"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>} */}

        {/* {timKiemNangCao && <Row>
          <Col xl="3">
            <Row>
              {timKiemNangCao && <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Giới tính</Form.Label>
                  <Autocomplete
                    options={GIOI_TINH}
                    value={formik.values?.gender || null}
                    onChange={selectedOption => handleChangeSeclection("gender", selectedOption)}
                    className="z-index-4"
                  />
                </Form.Group>
              </Col>}

              <Col xl={timKiemNangCao ? "6" : "12"}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Thống kê theo</Form.Label>
                  <Autocomplete
                    options={THONG_KE_THEO_NGAY}
                    value={formik.values?.thongKeTheo || null}
                    onChange={selectedOption => handleChangeSeclection("thongKeTheo", selectedOption)}
                    className="z-index-3"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Kỳ khai báo
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                options={KY_KHAI_CAO}
                value={formik.values?.kyKhaiBao || null}
                onChange={selectedOption => handleChangeSeclection("kyKhaiBao", selectedOption)}
                className="z-index-3"
              />
            </Form.Group>
          </Col>

          <Col xl="3">
            <Row>
              {formik.values?.kyKhaiBao?.code === "NAM" ? <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Năm</Form.Label>
                  <Autocomplete
                    options={OPTION_NAM}
                    value={formik.values?.namKhaiBao || null}
                    onChange={selectedOption => handleChangeSeclection("namKhaiBao", selectedOption)}
                    className="z-index-3"
                  />
                </Form.Group>
              </Col>
                :
                <Col> <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Tháng/Quý</Form.Label>
                  <Autocomplete
                    options={THANG_QUY}
                    value={formik.values?.thangQuy || null}
                    onChange={selectedOption => handleChangeSeclection("thangQuy", selectedOption)}
                    className="z-index-3"
                  />
                </Form.Group>
                </Col>}
            </Row>
          </Col>

          <Col xl="3">
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Kỳ thống kê từ ngày</Form.Label>
                  <Form.Control
                    name="kyThongKeFrom"
                    value={formik.values?.kyThongKeFrom || ""}
                    onChange={formik.handleChange}
                    type="date"
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Đến ngày</Form.Label>
                  <Form.Control
                    name="toKyThongKe"
                    value={formik.values?.toKyThongKe || ""}
                    onChange={formik.handleChange}
                    type="date"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>} */}

        {/* {timKiemNangCao && <Row>
          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Phân loại ca bệnh
              </Form.Label>
              <Autocomplete
                options={PHAN_LOAI_CA_BENH}
                value={formik.values?.phanLoaiCaBenh || null}
                onChange={selectedOption => handleChangeSeclection("phanLoaiCaBenh", selectedOption)}
                className="z-index-2"
              />
            </Form.Group>
          </Col>

          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Nơi điều trị hiện tại
              </Form.Label>
              <Autocomplete
                options={NOI_DIEU_TRI_HIEN_TAI}
                value={formik.values?.noiDieuTriHienTai || null}
                onChange={selectedOption => handleChangeSeclection("noiDieuTriHienTai", selectedOption)}
                className="z-index-2"
              />
            </Form.Group>
          </Col>

          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Cơ sở KCB điều trị
              </Form.Label>
              <Autocomplete
                optionLabel={"ten"}
                responseUrl="data"
                searchFunction={getCoSoYTe}
                searchObject={{ ...searchObject }}
                value={formik.values?.coSoDieuTri || null}
                onChange={selectedOption => handleChangeSeclection("coSoDieuTri", selectedOption)}
                className="z-index-2"
              />
            </Form.Group>
          </Col>

          <Col xl="3">
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">
                Biểu hiện bệnh
              </Form.Label>
              <Autocomplete
                options={BIEU_HIEN_BENH}
                value={formik.values?.bieuHienBenh || null}
                onChange={selectedOption => handleChangeSeclection("bieuHienBenh", selectedOption)}
                className="z-index-2"
              />
            </Form.Group>
          </Col>
        </Row>} */}

        <Row className="d-flex justify-content-center">
          <Button className="mr-8" type="submit">Thống kê</Button>
          <Button>Xuất báo cáo</Button>
        </Row>
      </Form>
    </div>
  )
}

export default ThongTinThongKe