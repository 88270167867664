import React, { useState } from "react";
import ApexCharts from "apexcharts";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { getDistrictReport } from "./TinhHinhKhaiBaoVaXacMinhService";
import "./bieuDo.scss";

export function TinhHinhKhaiBaoVaXacMinhHuyen({provinceCode, districtCode}) {
    const [subDistrictList, setSubDistrictList] = useState([]);
    const [dataSubDistrictList, setDataSubDistrictList] = useState([]);
    const [categories, setCategoties] = useState([]);
    const options = {
        series: [
            {
                name: "Số lượng",
                data: []
            },
        ],
        chart: {
            type: 'bar',
            height: 600
        },
        colors: ['#3498db', '#2ecc71', '#e74c3c', '#27ae60', '#FF5733',  '#f39c12', '#9b59b6' ],
        plotOptions: {
            bar: {
                columnWidth: '50%',
                barHeight: '100%',
                distributed: true,
                dataLabels: {
                    position: "top",
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function(val) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"],
            },
        },
        legend: {
            show: false
        },
        xaxis: {
            type: 'category',
            labels: {
                rotate: -90
            },
            categories: [],
            group: {
                style: {
                    fontSize: '20px',
                    fontWeight: 700,
                },
            }
        },
    };

    const sumDataSubDistrict = (dataSubDistricts, name) => {
        return dataSubDistricts.reduce((count, subDistrict) => count + subDistrict[name], 0);
    }

    useEffect(() => {
        const getDistrictSummaryReport = async () => {
            try {
                const { data } = await getDistrictReport({provinceCode, districtCode});
                let subDistricts = [];
                let dataSubDistricts = [];
                let categoriesTemp = [];
                const dataTemp = data?.data;

                dataTemp.forEach((subDistrict, index) => {
                    const {total, xnTotal, notXnTotal, xn48Hours, xnGreaterThan48Hours, totalSxh, totalNotSxh} = subDistrict;
                    dataSubDistricts = [
                        ...dataSubDistricts,
                        total ? total : 0, 
                        xnTotal ? xnTotal : 0, 
                        notXnTotal ? notXnTotal : 0, 
                        xn48Hours ? xn48Hours : 0, 
                        xnGreaterThan48Hours ? xnGreaterThan48Hours : 0, 
                        totalSxh ? totalSxh : 0, 
                        totalNotSxh ? totalNotSxh : 0
                    ];
                    subDistricts = [...subDistricts, { title: subDistrict?.subdistrictName, cols: 7 }];
                    categoriesTemp = [
                        ...categoriesTemp, 
                        "Tổng số khai báo",
                        "Đã xác minh",
                        "Chưa xác minh",
                        "Xác minh đúng 48h",
                        "Xác minh muộn",
                        "Là ca bệnh SXH",
                        "Không phải SXH",
                    ]
                })
                subDistricts.push({title: "Tổng cộng", cols: 7});
                dataSubDistricts.push(
                    sumDataSubDistrict(dataTemp, "total"),
                    sumDataSubDistrict(dataTemp, "xnTotal"),
                    sumDataSubDistrict(dataTemp, "notXnTotal"),
                    sumDataSubDistrict(dataTemp, "xn48Hours"),
                    sumDataSubDistrict(dataTemp, "xnGreaterThan48Hours"),
                    sumDataSubDistrict(dataTemp, "totalSxh"),
                    sumDataSubDistrict(dataTemp, "totalNotSxh"),
                )

                categoriesTemp.push(
                    "Tổng số khai báo",
                    "Đã xác minh",
                    "Chưa xác minh",
                    "Xác minh đúng 48h",
                    "Xác minh muộn",
                    "Là ca bệnh SXH",
                    "Không phải SXH",
                )
                setCategoties(categoriesTemp);
                setSubDistrictList(subDistricts);
                setDataSubDistrictList(dataSubDistricts);
            } catch (error) {
                toast.error("Có lỗi xảy ra vui lòng thử lại");
            }
        };
        getDistrictSummaryReport();
    }, [provinceCode, districtCode]) 

    useEffect(() => {
        const element = document.getElementById("chart-2");
        if (!element) {
            return;
        }

        const chartOptions = {
            ...options,
            chart: {
                type: 'bar',
                height: 600,
                width: subDistrictList.length * 300,
            },
            series: [
                {
                    name: "Số lượng",
                    data: dataSubDistrictList,
                },
            ],
            xaxis: {
                ...options.xaxis,
                categories: categories,
                group: {
                    style: {
                        fontWeight: 600,
                        marginTop: "10px"
                    },
                    groups: subDistrictList
                }
            }
        }

        const chart = new ApexCharts(element, chartOptions);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [subDistrictList, dataSubDistrictList, categories]);

    return (<>
        <Row className="chart-district">
            <Col xl={12}>
                <div className="chart-title">{`Tình hình khai báo và xác minh ca bệnh trên địa bàn huyện (Cập nhật đến ngày ${moment(new Date()).format("DD/MM/YYYY HH:MM")})`}</div>
            </Col>
            <Col xl={12}>
                <div id="chart-2" style={{width: "100%", overflow: "auto hidden"}}>

                </div>
            </Col>
        </Row>
    </>);
}