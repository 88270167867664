import { format } from "date-fns"

export const formatDateTable = (data, birthday = false) => {
  if (data) {
    let date;
    if (Array.isArray(data)) {
      const [year, month, day, hours, minutes] = data;
      date = new Date(year, month - 1, day, hours, minutes);
    } else {
      date = data;
    }
    return format(new Date(date), birthday ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm')
  } else {
    return ""
  }
}

export const formatDateForm = (data) => {
  if (data) {
    return format(new Date(data), 'yyyy-MM-dd')
  } else {
    return ""
  }
}

export const convertPhoneNumber = (number) => {
  if (number) {
    let newNumber = ""
    for (let i = 0; i < number.length; i++) {
      if (i > 2) {
        newNumber += "x";
      } else {
        newNumber += number[i];
      }
    }
    return newNumber;
  } else {
    return "";
  }
}