import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyAwseq6NFjQUDIivajmvyiQnZqOoH4XNtg",
    authDomain: "sskdt-59300.firebaseapp.com",
    projectId: "sskdt-59300",
    storageBucket: "sskdt-59300.appspot.com",
    messagingSenderId: "283414636544",
    appId: "1:283414636544:web:bfbaaa01404932779b90e5",
    measurementId: "G-XBC6C0QYPC"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
export { auth, firebase };
