import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { TableRow } from "./TableCollapseRow";
import { Form } from "react-bootstrap";

export default function TableCustom(props) {
  const { data,
    columns,
    headerClasses,
    bodyClasses,
    height,
    scrollable,
    isSingleSelect,
    maxHeight
  } = props;

  const [itemList, setItemList] = useState([]);
  const [currentColumns, setCurrentColumns] = useState([]);

  useEffect(() => {
    setItemList(data)
  }, [data]);

  useEffect(() => {
    if (isSingleSelect) {
      setCurrentColumns([...checkRadio, ...columns]);
    } else {
      setCurrentColumns([...columns]);
    }
  }, [])

  const styles = {
    height: height,
    maxHeight: maxHeight,
    overflowY: scrollable && 'auto',
  }

  const handleCheckRadio = (checked, index, listData) => {
    listData[index].isChecked = checked;
    listData.map((element, indexData) => {
      element.isChecked = indexData === index;
      return element
    });
    setItemList([...listData])
  }

  const checkRadio = [{
    // name: TYPE.SINGLE,
    field: "",
    headerStyle: {
      maxHeight: "20px",
      minWidth: "20px",
      textAlign: "center",
    },
    cellStyle: {
      textAlign: "center",
      paddingLeft: "10px"
    },
    isVisible: true,
    render: (rowData, index, numericalOrder, itemList) =>
      <Form.Check
        className="checkRadio"
        name="single"
        type={"radio"}
        checked={rowData?.isChecked}
        onChange={(event) => handleCheckRadio(event?.target?.checked, index, itemList)}
      />
  }]

  return (
    <div
      className="table-responsive table customs-collapse-row m-0"
      style={styles}
    >
      <table className="table-row-dashed dataTable table w-100" id="kt_table_users">
        <thead className={clsx(headerClasses, "position-sticky top-0")} style={{zIndex: "1"}}>
          <tr className="text-white fw-bolder text-uppercase gs-0 bg-pri border-0">
            {currentColumns?.map((column) =>
              <th
                className={clsx(
                  "p-table",
                  !column?.headerStyle && "min-w-40px",
                )}
                style={column?.headerStyle}
              >
                <div className="table-header">{column.name}</div>
              </th>
            )}
          </tr>
        </thead>
        <tbody className={clsx(bodyClasses)} >
          {itemList?.length > 0 ? itemList?.map((row, index) => (
            <TableRow
              row={row}
              index={index}
              itemList={itemList}
              columns={currentColumns}
              isSingleSelect={isSingleSelect}
              handleCheckRadio={handleCheckRadio}
            />
          ))
            :
            <tr>
              <td colSpan={columns?.length}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  Không có bản ghi nào
                </div>
              </td>
            </tr>}
        </tbody>
      </table>
    </div>
  );
};

