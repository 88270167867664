import React, { useState } from "react";
import ApexCharts from "apexcharts";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getSubdistrictReport } from "./TinhHinhKhaiBaoVaXacMinhService";
import moment from "moment";

export function TinhHinhKhaiBaoVaXacMinh({provinceCode, districtCode, subdistrictCode}) {
    const [reportData, setReportData] = useState([]);
    const options = {
        series: [{
            name: "Số lượng",
            data: [100, 80, 20, 60, 20, 70, 10]
        }],
        chart: {
            height: 350,
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            }
        },
        colors: ["#2E93fA"],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
                dataLabels: {
                    position: "top",
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function(val) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"],
            },
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                'Tổng số khai báo',
                'Đã xác minh',
                'Chưa xác minh',
                'Xác minh đúng 48h',
                'Xác minh muộn',
                'Là ca bệnh SXH',
                'Không phải SXH'
            ],
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        title: {
            text: `Tình hình khai báo và xác minh ca bệnh trên địa bàn (Cập nhật đến ngày ${moment(new Date()).format("DD/MM/YYYY HH:MM")})`,
            align: "center",
            style: {
                fontSize: "20px",
                fontWeight: "500"
            }
        }
    };



    useEffect(() => {
        const getSubdistrictSummaryReport = async () => {
            try {
                const { data } = await getSubdistrictReport({provinceCode, districtCode, subdistrictCode});
                const { total, xnTotal, notXnTotal, xn48Hours, xnGreaterThan48Hours, totalSxh, totalNotSxh } = data.data;
                setReportData([total??0, xnTotal??0, notXnTotal??0, xn48Hours??0, xnGreaterThan48Hours??0, totalSxh??0, totalNotSxh??0 ]);
            } catch (error) {
                toast.error("Có lỗi xảy ra vui lòng thử lại");
            }
        };
        getSubdistrictSummaryReport();
    }, [districtCode, provinceCode, subdistrictCode])

    useEffect(() => {
        const element = document.getElementById("chart-1");
        if (!element) {
            return;
        }

        const chartOptions = {
            ...options,
            series: [
                {
                    name: "Số lượng",
                    data: reportData,
                },
            ],
        };

        const chart = new ApexCharts(element, chartOptions);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [reportData]);

    return (<>
        <Row>
            <Col xl={12}>
                <div id="chart-1"></div>
            </Col>
        </Row>
    </>);
}
