import React from 'react';
import './PageLoading.scss';
import { Spinner } from 'react-bootstrap';

function PageLoading() {
    return <div className='page-loading-container'>
        <Spinner animation="border" className='custom-spinner'/>
    </div>;
}

export default PageLoading;