import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { TinhHinhKhaiBaoVaXacMinh } from "./bieu-do/TinhHinhKhaiBaoVaXacMinh";
import { TinhHinhKhaiBaoVaXacMinhHuyen } from "./bieu-do/TinhHinhKhaiBaoVaXacMinhHuyen";
import { TinhHinhKhaiBaoVaXacMinhThanhPho } from "./bieu-do/TinhHinhKhaiBaoVaXacMinhThanhPho";
import { Autocomplete } from "../modules/Component/Autocomplete";
import { VALIDATE_MESSAGE } from "../pages/khai-bao-thong-tin-sxh/constants";
import { ACCOUNT_LEVEL_CODE } from "../appConstants";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  getProvinces,
  getDistricts,
  getSubDistricts,
} from "../pages/khai-bao-thong-tin-sxh/KhaiBaoThongTinSXHService";
import { shallowEqual, useSelector } from "react-redux";

export function DashboardPage() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [province, setProvince] = useState(user?.organization?.provinceCode);
  const [district, setDistrict] = useState(user?.organization?.districtCode);
  const [subDistrict, setSubDistrict] = useState(user?.organization?.subDistrictCode);
  const getProvinceByUser = async () => {
    try {
      const { data } = await getProvinces({
        pageIndex: 1,
        pageSize: 1000,
        code: user?.organization?.provinceCode,
      });
      handleChangeSeclection("province", data.content[0]);
    } catch (error) {
      toast.error("Có lỗi xảy ra vui lòng thử lại");
    }
  };

  const getDistrictByUser = async () => {
    try {
      const { data } = await getDistricts({
        pageIndex: 1,
        pageSize: 1000,
        code: user?.organization?.districtCode,
      });
      handleChangeSeclection("district", data.content[0]);
    } catch (error) {
      toast.error("Có lỗi xảy ra vui lòng thử lại");
    }
  };

  const getSubDistrictByUser = async () => {
    try {
      const { data } = await getSubDistricts({
        pageIndex: 1,
        pageSize: 1000,
        code: user?.organization?.subDistrictCode,
      });
      handleChangeSeclection("subDistrict", data.content[0]);
    } catch (error) {
      toast.error("Có lỗi xảy ra vui lòng thử lại");
    }
  };



  useEffect(() => {
    getProvinceByUser();
    user?.organization?.districtCode && getDistrictByUser();
    user?.organization?.subDistrictCode && getSubDistrictByUser();
  }, []);

  const initialValues = {
    province: null,
    district: null,
    subDistrict: null,
  };

  const validationSchema = yup.object({
    province: yup
      .object()
      .nullable()
      .required(VALIDATE_MESSAGE.REQUIRED),
    district: yup
      .object()
      .nullable()
      .required(VALIDATE_MESSAGE.REQUIRED),
    subDistrict: yup
      .object()
      .nullable()
      .required(VALIDATE_MESSAGE.REQUIRED),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // handleSubmit(values);
    },
  });

  const handleChangeSeclection = (name, value) => {
    if (name) {
      formik.setFieldValue(name, value);
      // eslint-disable-next-line default-case
      switch (name) {
        case "province": setProvince(value); break;
        case "district": setDistrict(value); break;
        case "subDistrict": setSubDistrict(value); break;
      }

    }
  };

  const searchObject = {
    pageIndex: 1,
    pageSize: 1000,
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xl="4">
            <Form.Group className="mb-2">
              <Form.Label className="mb-1">
                Tỉnh/Thành phố
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                isDisabled={user?.organization?.provinceCode}
                value={formik.values?.province || null}
                searchFunction={getProvinces}
                searchObject={searchObject}
                responseUrl="data.content"
                onChange={(selectedOption) =>
                  handleChangeSeclection("province", selectedOption)
                }
                errors={formik.errors?.province}
                touched={formik.touched?.province}
              />
            </Form.Group>
          </Col>
          <Col xl="4">
            <Form.Group className="mb-2">
              <Form.Label className="mb-1">
                Quận/Huyện
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                value={formik.values?.district || null}
                searchFunction={getDistricts}
                searchObject={{
                  ...searchObject,
                  provinceCode: formik.values?.province?.code,
                }}
                responseUrl="data.content"
                onChange={(selectedOption) =>
                  handleChangeSeclection("district", selectedOption)
                }
                isDisabled={user?.organization?.districtCode}
                dependencies={[formik.values?.province]}
                errors={formik.errors?.district}
                touched={formik.touched?.district}
              />
            </Form.Group>
          </Col>
          <Col xl="4">
            <Form.Group className="mb-2">
              <Form.Label className="mb-1">
                Xã/Phường/Thị Trấn
                <span className="text-danger pl-1">*</span>
              </Form.Label>
              <Autocomplete
                value={formik.values?.subDistrict || null}
                searchFunction={getSubDistricts}
                searchObject={{
                  ...searchObject,
                  districtCode: formik.values?.district?.code,
                }}
                responseUrl="data.content"
                onChange={(selectedOption) =>
                  handleChangeSeclection("subDistrict", selectedOption)
                }
                isDisabled={user?.organization?.subDistrictCode}
                dependencies={[
                  formik.values?.province,
                  formik.values?.district,
                ]}
                errors={formik.errors?.subDistrict}
                touched={formik.touched?.subDistrict}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col xl="12" className="mb-20">

          {subDistrict && (
            <TinhHinhKhaiBaoVaXacMinh provinceCode={province.code} districtCode={district?.code} subdistrictCode={subDistrict?.code} />
          )}
          {(district && ACCOUNT_LEVEL_CODE.SUB_DISTRICT !== user?.organization?.level ) && (
            <TinhHinhKhaiBaoVaXacMinhHuyen provinceCode={province?.code} districtCode={district?.code} />
          )}
          {(province && ACCOUNT_LEVEL_CODE.DISTRICT !== user?.organization?.level && ACCOUNT_LEVEL_CODE.SUB_DISTRICT !== user?.organization?.level ) && (
            <TinhHinhKhaiBaoVaXacMinhThanhPho provinceCode={province?.code} />
          )}
        </Col>
      </Row>
    </>
  );
}
