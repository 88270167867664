import { IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { STATUS_ACTION } from '../../../appConstants';

function IconTable({ data, handleAction, isDelete, isView, isEdit, isConfirm, isRefuse }) {
  return (
    <div className="d-flex justify-content-center">
      {isDelete && <Tooltip title="Xóa bản ghi">
        <IconButton disabled={true} className='p-0 pr-2 text-danger' onClick={() => handleAction(data, STATUS_ACTION.DELETE)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>}

      {isView && <Tooltip title="Xem chi tiết">
        <IconButton className='p-0 pr-2 text-info' onClick={() => handleAction(data, STATUS_ACTION.VIEW)}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>}

      {isEdit && <Tooltip title="Sửa bản ghi">
        <IconButton className='p-0 pr-2 text-success' onClick={() => handleAction(data, STATUS_ACTION.EDIT)}>
          <EditIcon />
        </IconButton>
      </Tooltip>}

      {isConfirm && <Tooltip title="Xác nhận">
        <IconButton className='p-0 pr-2 text-primary' onClick={() => handleAction(data, STATUS_ACTION.CONFIRM)}>
          <CheckCircleIcon />
        </IconButton>
      </Tooltip>}

      {isRefuse && <Tooltip title="Từ chối">
        <IconButton className='p-0 pr-2 text-warning' onClick={() => handleAction(data, STATUS_ACTION.REFUSE)}>
          <ClearIcon />
        </IconButton>
      </Tooltip>}
    </div>
  )
}

export default IconTable