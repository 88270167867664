import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import TableCustom from '../../../modules/Component/table-custom/TableCustom';
import IconTable from '../../../modules/Component/icon-table/IconTable';
import { useState } from 'react';
import XacNhanDialog from './XacNhanDialog';
import { getAllPatients } from '../services/DanhSachMacBenhServices';
import { useEffect } from 'react';
import { convertPhoneNumber, formatDateTable } from '../../../utils/utils';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_ELEMENTS, DEFAULT_TOTAL_PAGES, KEY_NAME, STATUS_ACTION } from '../../../appConstants';
import { LIST_STATUS_PLCB, STATUS_PLCB } from '../constants/DanhSachMacBenhConstants';
import { toast } from 'react-toastify';
import { TablePagination } from '../../../modules/Component/TablePagination';
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from '../../../utils/PageUtils';
import SearchInput from '../../../modules/Component/input-search/SearchInput';
import { AppContext } from '../../../modules/Component/appContext/AppContextProvider';
import StatusColor from '../../../modules/Component/status-color/StatusColor';

function DanhSachMacBenhTable({ searchValues }) {
  const { setIsLoading } = useContext(AppContext);
  const [shouldOpenXacNhanDialog, setShouldOpenXacNhanDialog] = useState(false);
  const [rowData, setRowData] = useState({});
  const [listBenhNhan, setListBenhNhan] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [isConfirmAction, setIsConfirmAction] = useState("");
  const [isAddAction, setIsAddAction] = useState("");
  const [isView, setIsView] = useState(false);

  const [keywordSearch, setKeywordSearch] = useState("");
  const [page, setPage] = useState(DEFAULT_PAGE_INDEX);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [totalElements, setTotalElements] = useState(DEFAULT_TOTAL_ELEMENTS);

  useEffect(() => {
    handleGetAllPatients();
  }, [reloadTable, page, rowsPerPage, searchValues]);

  const handleAction = (rowData, action) => {
    switch (action) {
      case STATUS_ACTION.CONFIRM:
        handleShouldOpenXacNhanDialog(rowData, action)
        break;
      case STATUS_ACTION.EDIT:
        handleShouldOpenXacNhanDialog(rowData, action)
        break;
      case STATUS_ACTION.VIEW:
        handleShouldOpenXacNhanDialog(rowData, action)
        break;
      default:
        break;
    }
  }

  const handleGetAllPatients = async () => {
    const searchObject = {
      name: keywordSearch?.trim() || searchValues?.fullName?.trim(),
      provinceCode: searchValues?.province?.code,
      districtCode: searchValues?.district?.code,
      subdistrictCode: searchValues?.subDistrict?.code,
      pageSize: rowsPerPage,
      pageIndex: page,
    }
    setIsLoading(true);
    try {
      const { data } = await getAllPatients(searchObject);
      setIsLoading(false);
      setListBenhNhan(data?.content);
      setTotalElements(data?.totalElements);
      setTotalPages(data?.totalPages);
    } catch (error) {
      setIsLoading(false);
      toast.error("Có lỗi xảy ra vui lòng thử lại")
    }
  }

  const handleShouldOpenXacNhanDialog = (data, action) => {
    if (data?.id) {
      setRowData(data)
    } else {
      setRowData({})
    }
    setIsConfirmAction(action === STATUS_ACTION.CONFIRM)
    setIsAddAction(action === STATUS_ACTION.ADD || action === STATUS_ACTION.EDIT)
    setIsView(action === STATUS_ACTION.VIEW)
    setShouldOpenXacNhanDialog(true);
  }

  const handleCloseXacNhanDialog = () => {
    setShouldOpenXacNhanDialog(false);
    setReloadTable(!reloadTable);
  }

  const handleChangeKeywordSearch = (e) => {
    setKeywordSearch(e.target.value)
  }

  const handleKeyDown = (e) => {
    if(e.key === KEY_NAME.ENTER) {
      setPage(DEFAULT_PAGE_INDEX);
      handleGetAllPatients();
    }
  }

  const handleSearch = () => {
    setPage(DEFAULT_PAGE_INDEX);
    handleGetAllPatients();
  }
  
  const handleOnKeyUp = () => {
    if(!keywordSearch){
      setPage(DEFAULT_PAGE_INDEX);
      handleGetAllPatients();
    }
  }

  const columns = [
    {
      name: 'STT',
      field: '',
      headerStyle: {
        minWidth: "20px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData, index) => (index + 1),
    },
    {
      name: 'Thao tác',
      field: 'action',
      headerStyle: {
        minWidth: "95px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData, index) => (
        <IconTable
          data={rowData}
          handleAction={handleAction}
          isView
          isEdit={rowData?.xnStatus === STATUS_PLCB.CHUA_XAC_NHAN}
          isConfirm={rowData?.xnStatus === STATUS_PLCB.CHUA_XAC_NHAN}
        />
      )
    },
    {
      name: 'Ngày khởi phát',
      field: 'trieuChungDateStart',
      headerStyle: {
        minWidth: "130px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData) => formatDateTable(rowData?.trieuChungDateStart, true)
    },
    {
      name: 'Họ và tên',
      field: 'fullName',
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
    },
    {
      name: 'Ngày sinh',
      field: 'birthDate',
      headerStyle: {
        minWidth: "88px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData) => formatDateTable(rowData?.birthDate, true)
    },
    {
      name: 'Giới tính',
      field: 'gender',
      headerStyle: {
        minWidth: "79px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      name: 'Số điện thoại',
      field: 'phoneNumber',
      headerStyle: {
        minWidth: "114px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData) => convertPhoneNumber(rowData?.phoneNumber)
    },
    {
      name: 'Số CMND/CCCD',
      field: 'soCmnd',
      headerStyle: {
        minWidth: "119px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      name: 'Địa chỉ',
      field: 'hnAddress',
      headerStyle: {
        minWidth: "159px",
        textAlign: "center"
      },
      render: (rowData) => <div className="hidden-row">{rowData?.hnAddress}</div>
    },
    {
      name: 'Biểu hiện bệnh',
      field: 'trieuChungName',
      headerStyle: {
        minWidth: "177px",
        textAlign: "center"
      },
      render: (rowData) => <div className="hidden-row">{rowData?.trieuChungName?.replaceAll(";", "; ")}</div>
    },
    {
      name: 'Ngày khai báo',
      field: 'createDate',
      headerStyle: {
        minWidth: "122px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData) => formatDateTable(rowData?.createDate)
    },
    {
      name: 'Phân loại ca bệnh',
      field: 'xnStatus',
      headerStyle: {
        minWidth: "155px",
        textAlign: "center"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (rowData) => <StatusColor
        type={LIST_STATUS_PLCB.find(item => item.status === rowData?.xnStatus)?.status}
        children={LIST_STATUS_PLCB.find(item => item.status === rowData?.xnStatus)?.name}
      />
    },
  ]

  return (
    <div className="container-wrapper">
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button onClick={() => handleShouldOpenXacNhanDialog(null, "add")}>Thêm mới ca bệnh</Button>
          <SearchInput
            placeholder="Tìm kiếm theo họ và tên"
            name="keywordSearch"
            value={keywordSearch}
            handleChange={handleChangeKeywordSearch}
            handleKeyDown={handleKeyDown}
            handleSearch={handleSearch}
            handleKeyUp={handleOnKeyUp}
          />
        </div>
      </div>

      <div>
        <TableCustom
          data={listBenhNhan}
          columns={columns}
        />
        <TablePagination
          className="border-0 spaces px-4"
          page={page}
          setPage={setPage}
          handlePagesChange={handlePagesChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsForPage={rowsForPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPages={totalPages}
          totalElements={totalElements}
        />
      </div>

      {shouldOpenXacNhanDialog && 
        <XacNhanDialog
          isView={isView}
          isAddAction={isAddAction}
          isConfirmAction={isConfirmAction}
          rowData={rowData}
          open={shouldOpenXacNhanDialog}
          handleCloseDialog={handleCloseXacNhanDialog}
        />}
    </div>
  )
}

export default DanhSachMacBenhTable