import { useFormik } from 'formik';
import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import * as yup from "yup";
import { VALIDATE_MESSAGE } from '../constants';
import { useState } from 'react';
import { auth, firebase } from '../../../appConfig';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';


const XacThucOTPPopup = ({
    phoneNumber,
    handleClose,
}) => {
    const validationSchema = yup.object({
        maOTP: yup.string().required(VALIDATE_MESSAGE.REQUIRED),
    })

    const formik = useFormik({
        initialValues: {
            maOTP: ""
        },
        validationSchema,
        onSubmit: (values) => {
            handleVerifyOTP();
        }
    });

    const [confirmationResult, setConfirmationResult] = useState('');
    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          }
        });
      }
    
    const handleSendOTP = () => {
        if (phoneNumber === "") return;
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(confirmationResult);
            }).catch((error) => {
                // Error; SMS not sent
                console.log(error);
            });
    }

    const handleVerifyOTP = () => {
        if (formik.values?.maOTP === null) return;

        confirmationResult.confirm(formik.values?.maOTP).then((result) => {
            console.log("Success")
            toast.success("Xác nhận thành công");
            handleClose();
        })
            .catch((err) => {
                toast.error("Xác nhận thất bại");
            })
    }

    useEffect(() => {
        handleSendOTP();
    }, [])

    return (
        <Modal show={true} onHide={handleClose} centered className='background__modal'>
            {/* <Modal.Header className='bg-pri p-4 header-modal'>
                <Modal.Title className='text-white text-uppercase'>Lưu mẫu</Modal.Title>
            </Modal.Header> */}
             <div id="recaptcha"></div>
            <Modal.Body className='px-20 mt-5'>
                <Row className='mb-4 text-center'>
                    <Col xs="12">
                        <h4>Xác nhận OTP</h4>
                    </Col>
                    <Col xs="12">
                        Vui lòng nhập mã OTP đã được gửi tới số điện thoại
                    </Col>
                    <Col xs="12">
                        <h5 className='text-danger'>{phoneNumber}</h5>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col xs="12">
                        <Form.Group className="mb-3">
                            <Form.Control type="text" name="maOTP"
                                placeholder='Nhập mã OTP'
                                value={formik.values?.maOTP}
                                onChange={formik.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.maOTP}
                            </Form.Control.Feedback>
                            <p className="mt-2 font-italic">Mã OTP hết hạn sau 120s</p>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col xs="12">
                        Nếu bạn không nhận được tin nhắn OTP? <span
                            className='text-danger text-underline cursor-pointer'
                            onClick={() => handleSendOTP()}
                        >Gửi lại ngay</span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center p-2 pt-0 border-top-0 mb-5'>
                <Button className="btn-navy min-w-100px"
                    onClick={handleClose}>
                    Xác nhận
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default XacThucOTPPopup