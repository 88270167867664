import { useContext, useEffect, useState } from "react";
import React from "react";
import "./KhaiBaoThongTinSXH.scss";
import "../../../index.scss";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Autocomplete } from "../../modules/Component/Autocomplete";
import CustomCaptcha from "../../modules/Component/custom-captcha/CustomCaptcha";
import { useFormik } from "formik";
import { BIEU_HIEN_BENH, DATE_ISO_STRING_SLICE, DS_CO_SO_Y_TE, GENDER, KET_QUA_XET_NGHIEM, KHAI_BAO_STATUS, LOAI_XET_NGHIEM_DUOC_LAM, DS_NOI_DIEU_TRI_HIEN_TAI, TRAC_NGHIEM, VALIDATE_MESSAGE, NOI_DIEU_TRI_HIEN_TAI, XN_STATUS } from "./constants";
import { getCoSoYTe, getDistricts, getProvinces, getSubDistricts, postKhaiBaoThongTinSXH, updatePatients } from "./KhaiBaoThongTinSXHService";
import * as yup from "yup";
import { toast } from "react-toastify";
import { CODE_SUCCESS, REGEX } from "../../appConstants";
import XacThucOTPPopup from "./component/XacThucOTPPopup";
import { formatDateForm } from "../../utils/utils";
import { getPatientById } from "../danh-sach-mac-benh/services/DanhSachMacBenhServices";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AppContext } from "../../modules/Component/appContext/AppContextProvider";
import PageLoading from "../../modules/Component/page-loading/PageLoading";
import { shallowEqual, useSelector } from "react-redux";

export const KhaiBaoThongTinSXHContext = React.createContext(null)
const KhaiBaoThongTinSXH = ({rowData, isConfirmAction, isAddAction, isAdminAdd, isView, handleGetNguoiXacNhan, handleCloseDialog}) => {
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const { isLoading, setIsLoading } = useContext(AppContext);
    const [firstRender, setFirstRender] = useState(true);

    const initialValues = {
        id: rowData?.id || "",
        reportType: KHAI_BAO_STATUS.KHAI_BAO_CHO_MINH,
        fullName: rowData?.fullName || "",
        birthDate: rowData?.birthDate || "",
        gender: rowData?.gender || GENDER.NAM,
        soCmnd: rowData?.soCmnd || "",
        phoneNumber: rowData?.phoneNumber || "",
        hnAddress: rowData?.hnAddress || "",
        province: rowData?.province || null,
        district: rowData?.district || null,
        subDistrict: rowData?.subDistrict || null,
        trieuChung: rowData?.trieuChung || [],
        trieuChungDateStart: rowData?.trieuChungDateStart || "",
        isChanDoanSxh: rowData?.isChanDoanSxh || TRAC_NGHIEM.CO,
        isDengueTest: rowData?.isDengueTest || TRAC_NGHIEM.CO,
        kqxn: rowData?.kqxn || null,
        kqxnOther: rowData?.kqxnOther || "",
        noiDieuTri: rowData?.noiDieuTri || null,
        coSoDieuTri: rowData?.coSoDieuTri || null,
        isSxh: rowData?.isSxh || TRAC_NGHIEM.CO,
        isSxh2Week: rowData?.isSxh2Week || TRAC_NGHIEM.KHONG,
        nvytSupport: rowData?.nvytSupport || "",
        dengueXn: rowData?.dengueXn || null,
        maXacNhan: rowData?.maXacNhan || "",
        tenCoSoYTe: rowData?.tenCoSoYTe || "",
        bhytSo: rowData?.bhytSo || "",
        is48Hours: rowData?.is48Hours
    }

    const validationSchema = yup.object({
        reportType: yup.string().trim().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        fullName: yup.string().trim().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        birthDate: yup.date().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        gender: yup.string().trim().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        phoneNumber: yup.string().trim()
            .nullable()
            .matches(REGEX.CHECK_PHONE, "Số điện thoại không hợp lệ")
            .required(VALIDATE_MESSAGE.REQUIRED),
        hnAddress: yup.string().trim().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        province: yup.object().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        district: yup.object().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        subDistrict: yup.object().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        trieuChung: yup.array().nullable().min(1, VALIDATE_MESSAGE.REQUIRED),
        trieuChungDateStart: yup.date().nullable().required(VALIDATE_MESSAGE.REQUIRED),
        noiDieuTri: yup.object().nullable(),
        coSoDieuTri: yup.object().nullable().when('noiDieuTri.id', {
            is: NOI_DIEU_TRI_HIEN_TAI.TAI_BENH_VIEN,
            then: yup.object().nullable().required(VALIDATE_MESSAGE.REQUIRED)
        }),
        maXacNhan: yup.string().trim().nullable().when("$isAdminAdd", {
            is: false,
            then: yup.object().nullable().required(VALIDATE_MESSAGE.REQUIRED)
        }),
        soCmnd: yup.string().trim()
            .nullable()
            .matches(REGEX.CHARACTER9or12, VALIDATE_MESSAGE.CCCD),
        bhytSo: yup.string().trim().nullable().matches(REGEX.CHARACTER10, VALIDATE_MESSAGE.BHYT),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
            // setShouldOpenXacThucOTPPopup(true);
            // if (verifyOTPStatus) {
            // }
        }
    });
    const [captchaText, setCaptchaText] = useState("");
    const [verifyOTPStatus, setVerifyOTPStatus] = useState(false);
    const [shouldOpenXacThucOTPPopup, setShouldOpenXacThucOTPPopup] = useState(false);

    useEffect(() => {
        if (rowData?.id) {
            handleGetPatientById(rowData?.id);
        }
    },[rowData?.id])

    const handleGetPatientById = async (id) => {
        setIsLoading(true);
        try {
          const { data } = await getPatientById(id);
          if (data?.code === CODE_SUCCESS) {
            const idTrieuChung = data?.data?.trieuChungId?.split(";")?.map(item => item)
            const nameTrieuChung = data?.data?.trieuChungName?.split(";")?.map(item => item)
            const trieuChung = idTrieuChung?.map((id, index) => {
              return { id: id, name: nameTrieuChung[index] }
            })
    
            const convertData = {
              ...data?.data,
              birthDate: formatDateForm(data?.data?.birthDate),
              province: {
                code: data?.data?.hnProvinceCode,
                name: data?.data?.hnProvinceName
              },
              district: {
                code: data?.data?.hnDistrictCode,
                name: data?.data?.hnDistrictName
              },
              subDistrict: {
                code: data?.data?.hnSubdistrictCode,
                name: data?.data?.hnSubdistrictName
              },
              trieuChung: trieuChung,
              trieuChungDateStart: formatDateForm(data?.data?.trieuChungDateStart),
              isChanDoanSxh: data?.data?.isChanDoanSxh,
              isDengueTest: data?.data?.isDengueTest,
              kqxn: {
                code: data?.data?.kqxnCode,
                name: data?.data?.kqxnName
              },
              noiDieuTri: DS_NOI_DIEU_TRI_HIEN_TAI.find(item => item.id === +data?.data?.noiDieuTri),
              coSoDieuTri: {
                  ten: data?.data?.coSoDieuTriStr,
                  id: data?.data?.coSoDieuTri
              },
              isSxh: data?.data?.isSxh,
              isSxh2Week: data?.data?.isSxh2Week,
              dengueXn: {
                code: data?.data?.dengueXnCode,
                name: data?.data?.dengueXnName
              }
            };
            formik.setValues(convertData)
            setIsLoading(false);
          }
          handleGetNguoiXacNhan(data?.data);
        } catch (error) {
            setIsLoading(false);
            toast.error("Có lỗi xảy ra vui lòng thử lại")
        }
      }
    const searchObject = {
        pageIndex: 1,
        pageSize: 1000,
    }

    useEffect(() => {
        if (!firstRender) {
            formik.handleSubmit();
            setFirstRender(false);
        } else {
            setFirstRender(false);
        }
    }, [isAdminAdd])

    const getValueCaptchaText = (value) => {
        setCaptchaText(value);
    }

    const handleChangeSeclection = (name, value) => {
        if (name) {
            formik.setFieldValue(name, value);
        }
    }
    const handleSubmit = async (values) => {
        
        if (values.maXacNhan?.toLowerCase() === captchaText?.toLowerCase() || (isAdminAdd !== undefined && !firstRender)) {
            let trieuChungId = values?.trieuChung?.map(item => item?.id).join(';');
            let trieuChungName = values?.trieuChung?.map(item => item?.name).join(';');
            let trieuChungDateStart = new Date(values?.trieuChungDateStart).toISOString().slice(0,DATE_ISO_STRING_SLICE.NORMAL_DATE_TIME);
            let birthDate = new Date(values?.birthDate).toISOString().slice(0,DATE_ISO_STRING_SLICE.NORMAL_DATE);

            const submitData = {
                reportType: values?.reportType,
                hnAddress: values?.hnAddress,
                hnProvinceCode: values?.province?.code,
                hnProvinceName: values?.province?.name,
                hnDistrictCode: values?.district?.code,
                hnDistrictName: values?.district?.name,
                hnSubdistrictCode: values?.subDistrict?.code,
                hnSubdistrictName: values?.subDistrict?.name,
                trieuChungId,
                trieuChungName,
                trieuChungDateStart,
                isChanDoanSxh: values?.isChanDoanSxh,
                isSxh: values?.isSxh,
                isSxh2Week: values?.isSxh2Week,
                isDengueTest: values?.isDengueTest,
                kqxnCode: values?.kqxn?.code,
                kqxnName: values?.kqxn?.name,
                kqxnOther: values?.kqxnOther,
                noiDieuTri: values?.noiDieuTri?.id,
                coSoDieuTri: values?.coSoDieuTri?.id,
                nvytSupport: values?.nvytSupport,
                fullName: values?.fullName,
                phoneNumber: values?.phoneNumber,
                xnCoSo: values?.xnCoSo?.id,
                birthDate,
                xnStatus: XN_STATUS.CHUA_XAC_NHAN,
                gender: values?.gender,
                bhytSo: values?.bhytSo,
                soCmnd: values?.soCmnd,
                dengueXnCode: values?.dengueXn?.code,
                dengueXnName: values?.dengueXn?.name,
                tenCoSoYTe: values?.tenCoSoYTe
            }
            setIsLoading(true);
            try {
                let res;
                if (values?.id) {
                    res = await updatePatients(values?.id, submitData)
                } else {
                    res = await postKhaiBaoThongTinSXH(submitData);
                }
                if (res?.data?.code === CODE_SUCCESS) {
                    toast.success(values?.id ? "Cập nhật thành công" : "Thành công");
                    formik.resetForm();
                    isAddAction ? handleCloseDialog() : window.location.reload();
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    toast.error("Có lỗi xảy ra vui lòng thử lại");
                }
            } catch (e) {
                toast.error("Có lỗi xảy ra vui lòng thử lại");
                setIsLoading(false);
            }
        } else {
            toast.warning("Mã xác nhận không hợp lệ");
        }
    }

    const searchObjectProvince = {
        pageIndex: 1,
        pageSize: 1000,
        code: user?.organization?.provinceCode
      }
      const searchObjectDistrict = {
        pageIndex: 1,
        pageSize: 1000,
        code: user?.organization?.districtCode, 
        provinceCode: formik.values?.province?.code || user?.organization?.provinceCode
      }
      const searchObjectSubDistrict = {
        pageIndex: 1,
        pageSize: 1000,
        code: user?.organization?.subDistrictCode, 
        districtCode: formik.values?.district?.code || user?.organization?.districtCode
      }

    return (
        <KhaiBaoThongTinSXHContext.Provider value={null}>
            <>
                <div className="min-h-500px">
                    {isLoading && <PageLoading/>}
                    {(isConfirmAction || isAddAction || isView) ? "" :<h1 className="mb-0">Khai báo thông tin sốt xuất huyết</h1>}
                    <Form onSubmit={formik.handleSubmit}>
                       {(isConfirmAction || isAddAction || isView) ? "" : <Row>
                            <Col xs="12">
                                <FormGroup className="mb-2">
                                    <div className="d-flex">
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                className="cursor-pointer"
                                                id="reportType-1"
                                                type="radio" name="reportType"
                                                checked ={true}
                                                value={KHAI_BAO_STATUS.KHAI_BAO_CHO_MINH}
                                                onChange={formik.handleChange}
                                            />
                                            <Form.Check.Label htmlFor="reportType-1" className="cursor-pointer">
                                                <h5>Khai báo cho mình</h5>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                className="cursor-pointer"
                                                id="reportType-2"
                                                type="radio" name="reportType"
                                                value={KHAI_BAO_STATUS.KHAI_HO_NGUOI_THAN}
                                                onChange={formik.handleChange}
                                            />
                                            <Form.Check.Label htmlFor="reportType-2" className="cursor-pointer">
                                                <h5>Khai hộ người thân</h5>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className={`mt-2 ${formik.touched.xnStatus && formik.errors.xnStatus ? "d-block" : "d-none"}`}>
                                        {formik.errors.xnStatus}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>}
                        <Row>
                            <Col xl="12" className="mb-2">
                                <h4 className="text-primary">Thông tin người bệnh</h4>
                            </Col>

                            <Col xl="12" className="mb-4">
                                {(isConfirmAction || isView || formik.values?.is48Hours) && <div className="d-flex align-items-center">
                                    <div className='p-0 pr-2 text-primary'>
                                        <CheckCircleIcon />
                                    </div>
                                    <span><h5 className="mb-0">Trong 48h</h5></span>
                                </div>}
                            </Col>

                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Họ và tên
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Form.Control type="text"
                                        disabled={isConfirmAction || isView}
                                        name="fullName"
                                        value={formik.values?.fullName || ""}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.fullName && formik.errors.fullName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.fullName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xl="3">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Ngày tháng năm sinh
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="date" name="birthDate" 
                                        value={formik.values?.birthDate || ""}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.birthDate && formik.errors.birthDate}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.birthDate}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xl="2">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Giới tính
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <div className="d-flex gap-4 mt-2">
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="gender-1" type="radio" 
                                                name="gender"
                                                value={GENDER.NAM}
                                                checked={formik.values.gender === GENDER.NAM}
                                                onChange={formik.handleChange}
                                            />
                                            <Form.Check.Label htmlFor="gender-1" className="mt-1 cursor-pointer">
                                                <p>Nam</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="gender-2" type="radio" 
                                                name="gender"
                                                value={GENDER.NU}
                                                checked={formik.values.gender === GENDER.NU}
                                                onChange={formik.handleChange}
                                            />
                                            <Form.Check.Label htmlFor="gender-2" className="mt-1 cursor-pointer">
                                                <p>Nữ</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className={`mt-2 ${formik.touched.gender && formik.errors.gender ? "d-block" : "d-none"}`}>
                                        {formik.errors.gender}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Mã số BHXH/Số thẻ BHYT
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="bhytSo" 
                                        value={formik.values?.bhytSo || ""}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.bhytSo && formik.errors.bhytSo}
                                        placeholder="Nhập đối với đối tượng tham gia BHYT"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.bhytSo}
                                    </Form.Control.Feedback>
                                    {/* {!(formik.touched.bhytSo && formik.errors.bhytSo)  && <p className="mt-2 font-italic">Nhập đối với đối tượng tham gia BHYT</p>} */}
                                </Form.Group>
                            </Col>
                            <Col xl="3">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Số CMND/CCCD
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="soCmnd"
                                        value={formik.values?.soCmnd || ""}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.soCmnd && formik.errors.soCmnd}
                                        placeholder="Dưới 16 tuổi không cần nhập"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.soCmnd}
                                    </Form.Control.Feedback>
                                    {/* {!(formik.touched.soCmnd && formik.errors.soCmnd) && <p className="mt-2 font-italic">Dưới 16 tuổi không cần nhập</p>} */}
                                </Form.Group>
                            </Col>
                            <Col xl="5">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Số điện thoại
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="phoneNumber" 
                                        value={formik.values?.phoneNumber}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        placeholder="OTP xác thực sẽ gửi về số này"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.phoneNumber}
                                    </Form.Control.Feedback>
                                    {/* {!(formik.touched.phoneNumber && formik.errors.phoneNumber) && <p className="mt-2 font-italic">OTP xác thực sẽ gửi về số này</p>} */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Địa chỉ hiện tại
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="hnAddress" placeholder="Ghi rõ số nhà, tổ dân phố, đường, xóm" 
                                        value={formik.values?.hnAddress}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.hnAddress && formik.errors.hnAddress}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.hnAddress}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Tỉnh/Thành phố
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Autocomplete
                                        isDisabled={isConfirmAction || isView}
                                        value={formik.values?.province || null}
                                        searchFunction={getProvinces}
                                        searchObject={searchObjectProvince}
                                        responseUrl="data.content"
                                        onChange={selectedOption => handleChangeSeclection("province", selectedOption)}
                                        errors={formik.errors?.province}
                                        touched={formik.touched?.province}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Quận/Huyện
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Autocomplete
                                        value={formik.values?.district || null}
                                        searchFunction={getDistricts}
                                        searchObject={{ ...searchObjectDistrict}}
                                        responseUrl="data.content"
                                        onChange={selectedOption => handleChangeSeclection("district", selectedOption)}
                                        isDisabled={!formik.values?.province || isConfirmAction || isView}
                                        dependencies={[formik.values?.province]}
                                        errors={formik.errors?.district}
                                        touched={formik.touched?.district}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Xã/Phường/Thị Trấn
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Autocomplete
                                        value={formik.values?.subDistrict || null}
                                        searchFunction={getSubDistricts}
                                        searchObject={{ ...searchObjectSubDistrict }}
                                        responseUrl="data.content"
                                        onChange={selectedOption => handleChangeSeclection("subDistrict", selectedOption)}
                                        isDisabled={!formik.values?.district || isConfirmAction || isView}
                                        dependencies={[formik.values?.province, formik.values?.district]}
                                        errors={formik.errors?.subDistrict}
                                        touched={formik.touched?.subDistrict}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col xl="12" className="mb-2">
                                <h4 className="text-primary">Thông tin bệnh</h4>
                            </Col>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Biểu hiện bệnh (cho phép chọn nhiều)
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Autocomplete
                                        isDisabled={isConfirmAction || isView} 
                                        isMulti
                                        value={formik.values?.trieuChung}
                                        options={BIEU_HIEN_BENH}
                                        onChange={selectedOption => handleChangeSeclection("trieuChung", selectedOption)}
                                        errors={formik.errors?.trieuChung}
                                        touched={formik.touched?.trieuChung}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Ngày bắt đầu các biểu hiện trên
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="date" name="trieuChungDateStart" 
                                        value={formik.values?.trieuChungDateStart || ""}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.trieuChungDateStart && formik.errors.trieuChungDateStart}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.trieuChungDateStart}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Đã được đi khám, chẩn đoán mắc SXH chưa
                                    </Form.Label>
                                    <div className="d-flex gap-4 mt-2">
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isChanDoanSxh-1"
                                                type="radio" name="isChanDoanSxh"
                                                checked={formik.values.isChanDoanSxh === TRAC_NGHIEM.CO}
                                                onChange={() => handleChangeSeclection("isChanDoanSxh", TRAC_NGHIEM.CO)}
                                            />
                                            <Form.Check.Label htmlFor="isChanDoanSxh-1" className="mt-1 cursor-pointer">
                                                <p>Có</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isChanDoanSxh-2"
                                                type="radio" name="isChanDoanSxh"
                                                checked={formik.values.isChanDoanSxh === TRAC_NGHIEM.KHONG}
                                                onChange={() => handleChangeSeclection("isChanDoanSxh", TRAC_NGHIEM.KHONG)}
                                            />
                                            <Form.Check.Label htmlFor="isChanDoanSxh-2" className="mt-1 cursor-pointer">
                                                <p>Không</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Có được làm xét nghiệm Dengue không
                                    </Form.Label>
                                    <div className="d-flex gap-4 mt-2">
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isDengueTest-1"
                                                type="radio" name="isDengueTest"
                                                checked={formik.values.isDengueTest === TRAC_NGHIEM.CO}
                                                onChange={() => handleChangeSeclection("isDengueTest", TRAC_NGHIEM.CO)}
                                            />
                                            <Form.Check.Label htmlFor="isDengueTest-1" className="mt-1 cursor-pointer">
                                                <p>Có</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isDengueTest-2"
                                                type="radio" name="isDengueTest"
                                                checked={formik.values.isDengueTest === TRAC_NGHIEM.KHONG}
                                                onChange={() => handleChangeSeclection("isDengueTest", TRAC_NGHIEM.KHONG)}
                                            />
                                            <Form.Check.Label htmlFor="isDengueTest-2" className="mt-1 cursor-pointer">
                                                <p>Không</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xl="2">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Loại xét nghiệm được làm
                                    </Form.Label>
                                    <Autocomplete
                                        isDisabled={isConfirmAction || isView} 
                                        value={formik.values?.dengueXn || null} 
                                        options={LOAI_XET_NGHIEM_DUOC_LAM} 
                                        onChange={selectedOption => handleChangeSeclection("dengueXn", selectedOption)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="2">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Kết quả xét nghiệm
                                    </Form.Label>
                                    <Autocomplete
                                        isDisabled={isConfirmAction || isView} 
                                        value={formik.values?.kqxn || null}
                                        options={KET_QUA_XET_NGHIEM} 
                                        onChange={selectedOption => handleChangeSeclection("kqxn", selectedOption)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Đơn vị xét nghiệm
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="tenCoSoYTe" value={formik.values?.tenCoSoYTe}
                                        onChange={formik.handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Kết quả xét nghiệm khác
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="kqxnOther"
                                        value={formik.values.kqxnOther || ""}
                                        onChange={formik.handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Nơi điều trị hiện tại
                                    </Form.Label>
                                    <Autocomplete
                                        isDisabled={isConfirmAction || isView} 
                                        options={DS_NOI_DIEU_TRI_HIEN_TAI}
                                        value={formik.values?.noiDieuTri || ""}
                                        onChange={selectedOption => handleChangeSeclection("noiDieuTri", selectedOption)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="8">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Bệnh viện/ CSYT người bệnh điều trị (Yêu cầu chọn khi bạn điều trị tại bệnh viện)
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <Autocomplete
                                        optionLabel ={"ten"}
                                        searchFunction={getCoSoYTe}
                                        searchObject={{ ...searchObject }}
                                        onChange={selectedOption => handleChangeSeclection("coSoDieuTri", selectedOption)}
                                        responseUrl="data.data"
                                        value={formik.values?.coSoDieuTri || null}
                                        errors={formik.errors?.coSoDieuTri}
                                        touched={formik.touched?.coSoDieuTri}
                                        isDisabled={(formik.values?.noiDieuTri?.id !== NOI_DIEU_TRI_HIEN_TAI.TAI_BENH_VIEN) || isConfirmAction || isView}
                                        dependencies={[formik.values?.noiDieuTri]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Đã từng mắc SXH chưa
                                    </Form.Label>
                                    <div className="d-flex mt-2">
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isSxh-1"
                                                type="radio" name="isSxh"
                                                checked={formik.values.isSxh === TRAC_NGHIEM.CO}
                                                onChange={() => handleChangeSeclection("isSxh", TRAC_NGHIEM.CO)}
                                            />
                                            <Form.Check.Label htmlFor="isSxh-1" className="mt-1 cursor-pointer">
                                                <p>Có</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isSxh-2"
                                                type="radio" name="isSxh"
                                                checked={formik.values.isSxh === TRAC_NGHIEM.KHONG}
                                                onChange={() => handleChangeSeclection("isSxh", TRAC_NGHIEM.KHONG)}
                                            />
                                            <Form.Check.Label htmlFor="isSxh-2" className="mt-1 cursor-pointer">
                                                <p>Không</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xl="8">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Trong nhà có người bị mắc SXH trong vòng 2 tuần trở lại không?
                                        <span className="text-danger pl-1">*</span>
                                    </Form.Label>
                                    <div className="d-flex mt-2">
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isSxh2Week-1"
                                                type="radio" name="isSxh2Week"
                                                checked={formik.values.isSxh2Week === TRAC_NGHIEM.CO}
                                                onChange={() => handleChangeSeclection("isSxh2Week", TRAC_NGHIEM.CO)}
                                            />
                                            <Form.Check.Label htmlFor="isSxh2Week-1" className="mt-1 cursor-pointer">
                                                <p>Có</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check className="mr-4">
                                            <Form.Check.Input
                                                disabled={isConfirmAction || isView}
                                                className="cursor-pointer"
                                                id="isSxh2Week-2"
                                                type="radio" name="isSxh2Week"
                                                checked={formik.values.isSxh2Week === TRAC_NGHIEM.KHONG}
                                                onChange={() => handleChangeSeclection("isSxh2Week", TRAC_NGHIEM.KHONG)}
                                            />
                                            <Form.Check.Label htmlFor="isSxh2Week-2" className="mt-1 cursor-pointer">
                                                <p>Không</p>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                                <Form.Group className="mb-2">
                                    <Form.Label className="mb-1">
                                        Đề nghị NVYT hỗ trợ gì?
                                    </Form.Label>
                                    <Form.Control
                                        disabled={isConfirmAction || isView}
                                        type="text" name="nvytSupport" as="textarea" rows={2}
                                        className="resize-none"
                                        value={formik.values?.nvytSupport || ""}
                                        onChange={formik.handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {(isConfirmAction || isAddAction || isView) ? <></> :<>
                            <Row>
                                <Col xs="4">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="mb-0">
                                            Mã xác nhận
                                            <span className="text-danger pl-1">*</span>
                                        </Form.Label>
                                        <Form.Control type="text" name="maXacNhan"
                                            value={formik.values?.maXacNhan}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.maXacNhan && formik.errors.maXacNhan}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.maXacNhan}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col><Col xs="4">
                                    <CustomCaptcha getValue={(value) => getValueCaptchaText(value)} length={4} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="mb-2">
                                    <h4 className="text-danger">Lưu ý:</h4>
                                </Col>
                                <Col xs="12">
                                    <p className="text-danger mb-">* Vui lòng cung cấp đầy đủ thông tin để chúng tôi liên hệ và hỗ trợ bạn tốt nhất</p>
                                </Col>
                                <Col xs="12">
                                    <p className="text-danger mb-">* Xin vui lòng kiểm tra kỹ các thông tin (VD: Họ và tên, Ngày tháng năm sinh, Số điện thoại, Mã số BHXH/Số thẻ BHYT, Số CMND/soCmnd/Hộ chiếu,...)</p>
                                </Col>
                                <Col xs="12">
                                    <p className="text-danger mb-">* Việc bạn khai báo sẽ được bảo mật thông tin và chỉ cơ quan y tế mới có quyền được xem thông tin của bạn</p>
                                </Col>
                            </Row>
                            
                            <Row className="mt-10">
                                <Col className="d-flex justify-content-center">
                                    {/* <Button className="mx-2" variant="secondary">
                                        Hủy bỏ
                                    </Button> */}
                                    <Button className="mx-2" variant="primary" type="submit">
                                        Khai báo thông tin
                                    </Button>
                                </Col>
                            </Row>
                        </>}
                    </Form>
                </div>
                {shouldOpenXacThucOTPPopup && <XacThucOTPPopup
                    phoneNumber={formik.values.phoneNumber}
                    handleClose={() => setShouldOpenXacThucOTPPopup(false)}
                    setVerifyOTPStatus={(status) => setVerifyOTPStatus(status)}
                />}
            </>
        </KhaiBaoThongTinSXHContext.Provider>
    );
};
export { KhaiBaoThongTinSXH };
