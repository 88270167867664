import React from 'react'
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from '../../../../_helpers';

function MenuItem({ layoutProps, item }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return (
    <li
      className={`menu-item menu-item-rel ${getMenuItemActive(item?.path)} `}
      data-menu-toggle={item?.children?.length ? "hover" : ""}
    >
      <NavLink className={`menu-link ${item?.children?.length ? "menu-toggle" : ""}`} to={item?.path}>
        <span className="menu-text">{item?.title}</span>
        {item?.children?.length && (<i className="menu-arrow" />)}
      </NavLink>

      {item?.children?.length > 0 &&
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            {item?.children?.map(child => {
              return <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(child?.path)}`}
                data-menu-toggle={child?.children?.length ? "hover" : ""}
                aria-haspopup="true"
              >
                <NavLink className={`menu-link ${child?.children?.length ? "menu-toggle" : ""}`} to={child?.path}>
                  <span className="menu-text">
                    {child?.title}
                  </span>
                  {child?.children?.length && (<i className="menu-arrow" />)}
                </NavLink>

                {child?.children?.length > 0 &&
                  <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                    <ul className="menu-subnav">
                      {child?.children?.map(child2 => {
                        return <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(child2?.path)}`}
                          data-menu-toggle={child?.children?.length ? "hover" : ""}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to={child2?.path}>
                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                            <span className="menu-text">
                              {child2?.title}
                            </span>
                          </NavLink>
                        </li>
                      })}
                    </ul>
                  </div>}
              </li>
            })}
          </ul>
        </div>}
    </li>
  )
}

export default MenuItem