/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { Suspense } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { KhaiBaoThongTinSXH } from "./pages/khai-bao-thong-tin-sxh/KhaiBaoThongTinSXH";
import { DashboardPage } from "./pages/DashboardPage";

export function Routes() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { pathname } = useLocation();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        (pathname === "/auth/login" || pathname === "/logout") ?
          <Route>
            <AuthPage />
          </Route>
          :
          <Layout>
            <Suspense fallback={<LayoutSplashScreen />}>
              <Switch>
                {
                  <Redirect exact from="/" to="/khai-bao/khai-bao-thong-tin-sot-xuat-huyet" />
                }
                <Route path="/khai-bao/khai-bao-thong-tin-sot-xuat-huyet" component={KhaiBaoThongTinSXH} />
                <Route path="/dashboard" component={DashboardPage} />
                <Redirect to="error/error-v1" />
              </Switch>
            </Suspense>
          </Layout>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <BasePage path={isAuthorized && "/ho-so-chung-tu/ho-so-mac-benh-sot-xuat-huyet/danh-sach-mac-benh"} />
        </Layout>
      )}
    </Switch>
  );
}
