import axios from "axios";

const API_PATH = process.env.REACT_APP_API_URL;

export const getProvinceReport = (params) => {
    let url = API_PATH + "/reports/province-summary";
    return axios.get(url, {params});
}

export const getDistrictReport = (params) => {
    const url = API_PATH + "/reports/district-summary";
    return axios.get(url, {params})
}

export const getSubdistrictReport = (params) => {
    const url = API_PATH + "/reports/subdistrict-summary";
    return axios.get(url, {params});
}