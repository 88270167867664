export const handlePagesChange = (num, currentPage, setPage, totalPages) => {
  if (num === 1) {
    setPage(currentPage + 1)
  } else if (num === -1 && currentPage >= 2) {
    setPage(currentPage - 1)
  } else if (num === 2) {
    setPage(totalPages)
  } else if (num === -2) {
    setPage(1)
  }
}

export const handleRowsPerPageChange = (event, setRowsPerPage) => {
  setRowsPerPage(parseInt(event.target.value))
}

export const rowsForPage = [1, 2, 3, 5, 10, 15, 20, 50, 100]