import axios from "axios";

const API_PATH = process.env.REACT_APP_API_URL;
const PUBLIC_API_PATH = process.env.REACT_APP_API_URL+"/public";

export const getProvinces = (params) => {
    let url = PUBLIC_API_PATH + "/province";
    return axios.get(url, {params});
}

export const getDistricts = (params) => {
    let url = PUBLIC_API_PATH + "/districts";
    return axios.get(url, { params });
}

export const getSubDistricts = (params) => {
    let url = PUBLIC_API_PATH + "/subdistricts";
    return axios.get(url, { params });
}

export const postKhaiBaoThongTinSXH = (data) => {
    let url = PUBLIC_API_PATH + "/patients";
    return axios.post(url, data);
}

export const updatePatients = (id, data) => {
    let url = API_PATH + "/patients/" + id;
    return axios.put(url, data);
}

export const getCoSoYTe = (params) => {
    let url = PUBLIC_API_PATH + "/so-so-y-te";
    return axios.get(url, { params });
}