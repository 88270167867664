export const BIEU_HIEN_BENH = [
    { id: "TC001", name: "Sốt cao liên tục từ 2-7 ngày" },
    { id: "TC002", name: "Nhức đầu, chán ăn, buồn nôn hoặc nôn" },
    { id: "TC003", name: "Da xung huyết, phát ban" },
    { id: "TC004", name: "Đau cơ, đau khớp, nhức hai hố mắt" },
    { id: "TC005", name: "Có dấu hiệu xuất huyết ở các mức độ khác nhau: Chấm xuất huyết ngoài da, chảy máu chân răng, chảy máu cam, đi ngoài phân đen, đi ngoài ra máu, nôn máu, kinh nguyệt sớm/kinh nguyệt kéo dài" },    
    { id: "TC006", name: "Vật vã, li bì" },
    { id: "TC007", name: "Đau bụng vùng gan (hạ sườn phải) hoặc ấn đau vùng gan" },
]

export const NOI_DIEU_TRI_HIEN_TAI = {
    TU_DIEU_TRI_TAI_NHA: 1,
    TAI_NHA: 2,
    TAI_BENH_VIEN: 3,
    DA_KHOI_BENH: 4,
}

export const DS_NOI_DIEU_TRI_HIEN_TAI = [
    { id: NOI_DIEU_TRI_HIEN_TAI.TU_DIEU_TRI_TAI_NHA, name: "Tự điều trị tại nhà (Không khám qua CSYT)" },
    { id: NOI_DIEU_TRI_HIEN_TAI.TAI_NHA, name: "Đang điều trị tại nhà" },
    { id: NOI_DIEU_TRI_HIEN_TAI.TAI_BENH_VIEN, name: "Đang điều trị tại bệnh viện" },
    { id: NOI_DIEU_TRI_HIEN_TAI.DA_KHOI_BENH, name: "Đã khỏi bệnh" },
]

export const LOAI_XET_NGHIEM_DUOC_LAM = [
    { code: 1, name: "NS1" },
    { code: 2, name: "IgM" },
    { code: 3, name: "IgG" },
]

export const KET_QUA_XET_NGHIEM = [
    { code: 1, name: "Dương tính" },
    { code: 2, name: "Âm tính" },
]

export const DS_CO_SO_Y_TE = [
    { id: 1, name: "Bệnh viện đa khoa Xanh Pôn" },
    { id: 2, name: "Bệnh viện Quân Y 354" },
    { id: 3, name: "Bệnh viện đa khoa Hòe Nhai" },
    { id: 4, name: "Bệnh viện đa khoa Hòe Nhai - Cơ sở 2" },
    { id: 5, name: "Phòng khám đa khoa 50 Hàng Bún (TTYT Ba Đình)" },
    { id: 6, name: "Bệnh viện YHCT Nam Á" },
]

export const KHAI_BAO_STATUS = {
    KHAI_BAO_CHO_MINH: 1,
    KHAI_HO_NGUOI_THAN: 2
}

export const GENDER = {
    NAM: "Nam",
    NU: "Nữ"
}

export const TRAC_NGHIEM = {
    CO: true,
    KHONG: false
}

export const VALIDATE_MESSAGE = {
    REQUIRED: "Trường này là bắt buộc",
    CCCD: "Nhập đúng 9 hoặc 12 chữ số",
    BHYT: "Mã số không hợp lệ",
    CAPTCHA: "Vui lòng nhập mã xác nhận"
}


export const DATE_ISO_STRING_SLICE = {
    NORMAL_DATE_TIME: 19,
    NORMAL_DATE: 10
}

export const XN_STATUS = {
    CHUA_XAC_NHAN: 1,
    DANG_DIEU_TRA_XAC_MINH: 2,
    XAC_NHAN_LOAI_TRU: 3,
    XAC_NHAN_DUNG_CA_BENH: 4   
}